export const WEBINAR_TOPICS = "/getWebinarTopics",
	REQUEST_WEBINAR = "/requestWebinar",
	CREATE_USER = "/registration",
	RESET_PASSWORD = "/resetPassword",
	CHANGE_PASSWORD = "/changePassword?resetToken=",
	CHANGE_PASSWORD_PROFILE = "/api/changePassword",
	CHANGE_CREDIT_CARD = "/api/creditCardChange",
	STARE_AND_CITIES_LIST = "/api/getStateCityList/",
	USER_VERIFY = "/notes/verifyUserForLanding?verificationToken=",
	RECALCULATE_USER_NOTE = "/recalculateUserNote",
	LOGOUT = "/logout",
	LOGIN = "/api/auth/login",
	REFRESH_TOKEN_PATH = "/api/auth/token",
	SEND_FEEDBACK = "/sendFeedback",
	FEEDBACK_TOPICS = "/getFeedbackTopics",
	CONTACT_US = "/contactUs",
	ANNOUNCEMENTS_ANONYMOUS = "/getAnnouncementsForAnonymous",
	ANNOUNCEMENTS_USER = "/api/getAnnouncements",
	TESTIMONIALS = "/getTestimonials",
	DATA_FOR_HOME_PAGE_AUTH = "/api/getDataForHomePage",
	DATA_FOR_HOME_PAGE = "/getDataForHomePage",
	TERMS = "/subscription/getActualAgreement",
	RESIGN_AGREEMENT = "/api/resignAgreement",
	USER_DETAIL = "/api/userDetail",
	USER_DATA = "/api/getUserActualData",
	USER_PAYMENTS = "/api/getUserPayments",
	UPDATE_USER = "/api/updateUser",
	CANCEL_USER_SUBSCRIPTION = "/api/cancelUserSubscription",
	CHECK_NEW_ANNOUNCMENTS = "/api/checkNewAnnouncements",
	HIDE_ANNOUNCEMENTS = "api/hideReleaseNotes",
	RELEASE_NOTES = "api/getReleaseNotes",
	UNREAD_USER_LOGS_COUNT = "/api/getUnreadUserLogsCount",
	DISPLAYED_USER_LOGS = "/api/getDisplayedUserLogs",
	MARK_USER_LOGS = "/api/markUserLogs",
	BUNDLE_SUBSCRIPTIONS = "/subscription/getProfessionalPlans",
	BUNDLE_SUBSCRIPTIONS_AUTH = "/api/getProfessionalPlans",
	PERSONAL_SUBSCRIPTIONS = "/subscription/getPersonalPlans",
	PERSONAL_SUBSCRIPTIONS_AUTH = "/api/getPersonalPlans",
	ALACARTE_SUBSCRIPTIONS = "/api/getDataForAlaCarte",
	PARAMS_FOR_PAYMENT = "/api/getParamsForPaymentSystems",
	DATA_FOR_PAYMENT = "/api/getDataForSubscriptionPaymentScreen",
	APPLY_PROMO = "/api/applyPromoCode",
	APPLY_REGISTRATION_PROMO = "/applyRegistrationPromoCode",
	CREATE_SUBSCRIPTION = "/api/createAlaCarteSubs",
	STRIPE_SUBSCRIPTION = "/api/stripeSubscriptionWithAddOns",
	GET_PRIVILEGE_FOR_REGISTRATION = "/getPrivilegeForRegistration/",
	TERMS_HTML = process.env.VUE_APP_BASE_URL + "agreement.html",
	BLANC_IMAGE = process.env.VUE_APP_BASE_URL + "src/img/home_bkg.png",
	CREATE_API_KEY = "/api/auth/apiKey/",
	GET_API_KEY_LIST = "/api/apiKeyList/",
	DELETE_API_KEY = "/api/apiKey/",
	GET_REPORT_REDEEMING_API = "/request-status/",
	GET_REPORT_SUMMARY_API = "/request-status/",
	VENDOR_CONTACT_US = "/vendorContactUs",
	GET_NOTE_DASHBOARD = "/api/getNoteDashboardData",
	FETCH_ALL_NOTES = "/api/fetchAllForSaleNotes",
	GET_NOTES_FOR_SALE = "/api/getNoteForSaleDashboardData",
	GET_MANAGE_USER_GROUPS = "/api/manageUserGroups",
	JOIN_IN_USER_GROUP = "/api/joinInUserGroup",
	LEAVE_FROM_USER_GROUP = "/api/leaveFromUserGroup",
	REMOVE_USER_FROM_GROUP = "/api/removeUserFromGroup",
	ADD_USER_TO_GROUP = "/api/addUserToGroup",
	UPLOAD_USER_LOGO = "/api/updateUserLogo",
	COPY_NOTE_FOR_USER = "/api/copyNoteForUser",
	GET_FILTERED_USER_PAYMENT_HISTORY_COUNT = "/api/getFilteredUserPaymentHistoryCount",
	GET_EXTENDED_DATA_FOR_SUBSCRIPTION = "/api/getExtendedDataForSubscriptionPaymentScreen";

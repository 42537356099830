<template>
  <div class="md-layout md-alignment-center-center">
    <div class="md-group" v-if="period">
      <md-button
        class="md-round"
        v-for="item in options"
        :key="item.value"
        :class="getClassButtonGroup(item)"
        @click="$emit('cangeplan', item)"
      >
        {{ $t(item.text) }}
      </md-button>
    </div>
  </div>
</template>

<script>
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";

export default {
  name: "PlanOptions",
  props: ["period"],
  data() {
    return {
      options: [
        SubscriptionPeriod.annually,
        //SubscriptionPeriod.quarterly,
        SubscriptionPeriod.monthly,
      ],
    };
  },

  computed: {
    getColorButton(colorButton) {
      return "md-" + colorButton + "";
    },
    getClassButtonGroup() {
      return (item) =>
        this.period.value == item.value ? "md-cyan-900" : "md-grey-300";
    },
  },
};
</script>

import http from "@/http-common";
import Api from "@/api/methods/Api";
import {
  ANNOUNCEMENTS_USER,
  CHECK_NEW_ANNOUNCMENTS,
  ANNOUNCEMENTS_ANONYMOUS,
  HIDE_ANNOUNCEMENTS,
  RELEASE_NOTES,
} from "@/api/Constants";

class ApiAnnouncements extends Api {
  announcementsUser(data, token) {
    return http.post(ANNOUNCEMENTS_USER, data, this.headers(token));
  }

  hideAnnouncements(token, _messageIdList) {
    return http.post(
      HIDE_ANNOUNCEMENTS,
      {
        screenName: "ReleaseNotes",
        messageCode: "release_notes",
        messageIdList: _messageIdList,
      },
      this.headers(token)
    );
  }

  releaseNotes(token, _skip) {
    return http.post(
      RELEASE_NOTES,
      {
        screenName: "ReleaseNotes",
        type: "isLogin",
        isOld: true,
        skip: _skip,
      },
      this.headers(token)
    );
  }

  checkNewAnnouncements(token) {
    return http.get(CHECK_NEW_ANNOUNCMENTS, this.headers(token));
  }

  announcements(token, data) {
    if (token) {
      return this.announcementsUser(data, token);
    } else {
      return this.announcementsAnonimous(data);
    }
  }

  //Announcements anonimous
  announcementsAnonimous(data) {
    return http.post(ANNOUNCEMENTS_ANONYMOUS, data);
  }
}

export default new ApiAnnouncements();

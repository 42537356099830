import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router/router";
import Vuelidate from "vuelidate";
import MaterialKit from "./plugins/material-kit";
import i18n from "./i18n";
import VueToastr from "vue-toastr";
import {VTooltip,VPopover, VClosePopover } from "v-tooltip";
import VueCrontab from "vue-crontab";
import Vue2TouchEvents from "vue2-touch-events";
import VueWaypoint from "vue-waypoint";
import VueVirtualScroller from "vue-virtual-scroller";
import VueAspectRatio from "vue-aspect-ratio";
import "./modernizrWebp.js";
import "element-ui/lib/theme-chalk/index.css";
import "primevue/resources/themes/md-light-indigo/theme.css";
import "primeicons/primeicons.css";
import Loading from "@/plugins/loader/js/LoaderSpinner";
import VueScreen from "vue-screen";
import PrimeVue from "primevue/config";
import VueClipboard from "vue-clipboard2";
import ClickOutside from "vue-click-outside";
import VueCookies from 'vue-cookies';
import VueMask from 'v-mask'
import { VclFacebook, VclInstagram } from 'vue-content-loading';
import * as VueGoogleMaps from 'vue2-google-maps';
import store from "./store/Global";
Vue.component("vue-aspect-ratio", VueAspectRatio);
Vue.config.productionTip = false;

const pluginsArray = [
  Vuex,
  PrimeVue,
  VueVirtualScroller,
  VueCrontab,
  MaterialKit,
  Vuelidate,
  VueToastr,
  VTooltip,
  VClosePopover,
  VPopover,
  Vue2TouchEvents,
  VueWaypoint,
  VueScreen,
  VueClipboard,
  VueMask,
  VclFacebook,
  VclInstagram,
  ClickOutside,
];
pluginsArray.forEach((x) => Vue.use(x));
Vue.use(VueCookies, { expires: "7d" });
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDsNsilVDDPMLqCuO98pe2qFN80uNOPTgA",
    libraries: "places,drawing",
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true,
});
Vue.component("loading", Loading);
Vue.prototype.chart = {};

const NavbarStore = {
  showNavbar: false,
};

const LoadingStore = {
  isLoading: false,
};

const FooterStore = {
  showFooter: true,
};

const AgreementStore = {
  isExpired: false,
};

Vue.mixin({
  data() {
    return {
      NavbarStore,
      LoadingStore,
      FooterStore,
      AgreementStore
    };
  },
});

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  store
}).$mount("#app");

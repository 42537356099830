<template>
  <div :class="classes">
    <md-table v-if="items">
      <md-table-row slot="md-table-row" v-for="item in items" :key="item.name">
        <md-table-cell class="md-medium-size-60 md-xsmall-size-80 md-size-80">
          <span>{{ item.name }}</span>
        </md-table-cell>
        <md-table-cell class="md-medium-size-20 md-xsmall-size-20 md-size-20">
          <span>{{ item.price }}</span>
        </md-table-cell>
        <md-table-cell>
          <md-button
            class="md-just-icon md-simple md-danger"
            @click="$emit('')"
          >
            <md-icon>close</md-icon>
            <md-tooltip md-direction="top">Close</md-tooltip>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "purchase-table",
  props: {
    classes: {
      type: String,
      default: null,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
  },

  methods: {
    onSelect: function (items) {
      //this.selected = items;
    },
    getActiveCollapse(index) {
      return this.isActive == index;
    },
  },
};
</script>

import http from "@/http-common";
import Api from "@/api/methods/Api";
import axios from "axios";
import {
  FEEDBACK_TOPICS,
  SEND_FEEDBACK,
  CONTACT_US,
  ANNOUNCEMENTS_ANONYMOUS,
  TESTIMONIALS,
  TERMS,
  WEBINAR_TOPICS,
  REQUEST_WEBINAR,
  TERMS_HTML,
  DATA_FOR_HOME_PAGE,
  DATA_FOR_HOME_PAGE_AUTH,
  VENDOR_CONTACT_US,
} from "@/api/Constants";
import { WP_LINKS } from "@/plugins/Constants";

class ApiLanding extends Api {
  //Get feedback topics
  getFeedbackTopics() {
    return http.get(FEEDBACK_TOPICS);
  }

  //Send Feedback
  sendFeedback(data) {
    return http.post(SEND_FEEDBACK, data);
  }

  //Contact us
  contactUs(data) {
    return http.post(CONTACT_US, data);
  }

  //Announcements anonimous
  announcementsAnonimous(data) {
    return http.post(ANNOUNCEMENTS_ANONYMOUS, data);
  }

  //Testimonials
  getTestimonials() {
    return http.get(TESTIMONIALS);
  }

  dataForHomePage() {
    return http.get(DATA_FOR_HOME_PAGE);
  }

  dataForHomePageAuth(token) {
    return http.get(DATA_FOR_HOME_PAGE_AUTH, this.headers(token));
  }

  getHome(token) {
    if (token) {
      return this.dataForHomePageAuth(token);
    }
    return this.dataForHomePage();
  }

  //Terms get actual agreement
  getActualAgreement() {
    return http.get(TERMS);
  }

  getAgreement() {
    return http.get(TERMS_HTML);
  }

  //Webinar topics
  getWebinarTopics() {
    return http.get(WEBINAR_TOPICS);
  }

  getBlogs() {
    return http.get(WP_LINKS.blogRequest);
  }

  getMedia(id) {
    return http.get(WP_LINKS.media + id);
  }

  //requestWebinar
  requestWebinar(data) {
    return http.post(REQUEST_WEBINAR, data);
  }
  sendVendorInfo(data) {
    return axios
      .create({
        baseURL: process.env.VUE_APP_API_URL + "/",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .post(VENDOR_CONTACT_US, data);
  }
}

export default new ApiLanding();

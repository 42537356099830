export const round = (value, decimals) => {
  if (value && value != 0) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  } else if (value == 0) return 0;
};

export const filter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const calculateRateVlad = (
  term,
  payment,
  principal,
  future,
  type,
  guess
) => {
  if (payment > 0) payment = -1 * payment;
  var FV = future === undefined ? 0 : future;
  var MTYPE = type === undefined ? 0 : type;
  var NPER = term;
  var PMT = payment;
  var PV = principal;
  var R = Math.abs((PMT * NPER) / (PV - FV)) - 1;
  var NOMINATOR,
    R1,
    DENOMINATOR,
    IP_POW_NPER,
    IP_POW_NPER_1,
    K,
    TERM11,
    TERM12,
    TERM21,
    TERM22;
  var j = 0;
  if (R <= 0) {
    return R;
  }
  if (R > 5) {
    R = 5;
  }

  label1: for (; ; j++) {
    IP_POW_NPER = Math.pow(1 + R, NPER);
    IP_POW_NPER_1 = Math.pow(1 + R, NPER - 1);
    TERM11 = PV * IP_POW_NPER;
    TERM12 = PMT * ((IP_POW_NPER - 1) / R + (IP_POW_NPER - 1) * MTYPE);
    NOMINATOR = TERM11 + TERM12 + FV;
    TERM21 = Math.abs(PV * NPER * IP_POW_NPER_1);
    TERM22 = Math.abs(
      PMT *
        ((NPER * IP_POW_NPER_1 * R - (IP_POW_NPER - 1)) / Math.pow(R, 2) +
          NPER * IP_POW_NPER_1)
    );
    DENOMINATOR = TERM21 + TERM22;
    R1 = R - NOMINATOR / DENOMINATOR;
    if (Math.abs(R - R1) < 0.000001) {
      return R;
    } else {
      R = R1;
    }
    if (j < 500000) {
      continue label1;
    }
    break label1;
  }
};

export const getNPER = (rate, payment, present, future, type) => {
  payment = -1 * Math.abs(payment);
  rate = parseFloat(rate);
  var nper = null;
  if (payment != null && payment != 0 && present != null && present != 0) {
    if (rate == 0) {
      if (payment != 0) {
        nper = Math.abs(Math.ceil(present / payment));
      }
    } else {
      // Initialize type
      var type = typeof type === "undefined" ? 0 : type;
      // Initialize future value
      var future = typeof future === "undefined" ? 0 : future;
      // Return number of periods
      var num = payment * (1 + rate * type) - future * rate;
      var den = present * rate + payment * (1 + rate * type);
      var nper = Math.log(num / den) / Math.log(1 + rate);
      nper = Math.ceil(nper);
    }
  }
  return nper;
};

export const getPV = (rate, periods, payment, future, type) => {
  future = future || 0;
  type = type || 0;

  // Return present value
  if (rate === 0) {
    return Math.abs(payment) * periods - future;
  } else {
    return (
      (((1 - Math.pow(1 + rate, periods)) / rate) *
        payment *
        (1 + rate * type) -
        future) /
      Math.pow(1 + rate, periods)
    );
  }
};

export const sanitizeInput = (value) => {
  if (value) {
    var temp = value.toString().replace(/\$/g, "");
    temp = temp.replace(/\%/g, "");
    return temp.replace(/\,/g, "");
  }
  return value;
};

import { Model } from "vue-mc";

class UsersLogModel extends Model {
  options() {
    return {
      editable: true,
      mutateOnChange: true,
    };
  }

  defaults() {
    return {
      status: null,
      userLogId: 0, // primary
      action: null,
      dateTime: null,
      comment: null,
      groupedAmount: null,
      display: null,
      message: null,
      selected: false,
      actionNeeded: false,
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      status: String,
      userLogId: Number,
      action: String,
      dateTime: String,
      comment: String,
      groupedAmount: String,
      display: String,
      message: String,
      selected: Boolean,
      actionNeeded: Boolean,
    };
  }
}

export default UsersLogModel;

export default {
  namespaced: true,
  state: {
    userType: null,
    showMessages: [],
    chosenBuyer: null,
    windowOpened: false
  },
  actions: {
    showMessaging({commit}, userType) {
      commit("setWindowState", true);
      commit("setUserType", userType);
    },
    closeMessaging({commit}) {
      commit("setWindowState", false);
    }
  },
  mutations: {
    setUserType(state, type) {
      state.userType = type;
    },
    setMessages(state, messages) {
      state.showMessages = messages;
    },
    setChosenBuyer(state, buyer) {
      state.chosenBuyer = buyer;
    },
    setWindowState(state, windowState) {
      state.windowOpened = windowState;
    }
  }
}
<template>
  <md-progress-spinner
    class="md-cyan-900"
    :md-diameter="width"
    :md-stroke="height"
    md-mode="indeterminate"
  ></md-progress-spinner>
</template>

<script>
export default {
  name: "spinner",
  props: {
    color: {
      type: String,
      default: "#000",
    },
    height: {
      type: Number,
      default: 60,
    },
    width: {
      type: Number,
      default: 60,
    },
  },
};
</script>

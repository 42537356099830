const SubscriptionPeriod = Object.freeze({
  monthly: {
    letter(num) {
      return num + " M";
    },
    index: 2,
    div: 1,
    value: "monthly",
    query: "monthly",
    text: "plans-page.monthly",
    priceKeySubscription: "priceMonthly",
    forPayment: "month",
  },
  quarterly: {
    letter(num) {
      return 3 * num + " M";
    },
    index: 1,
    div: 3,
    value: "quarterly",
    query: "quarterly",
    text: "plans-page.quarterly",
    priceKeySubscription: "priceQuarterly",
    forPayment: "quarter",
  },
  annually: {
    letter: "Y",
    index: 0,
    div: 12,
    value: "annually",
    query: "annually",
    text: "plans-page.annual",
    priceKeySubscription: "priceAnnually",
    forPayment: "year",
  },
});

export default SubscriptionPeriod;

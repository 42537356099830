<template>
  <div v-click-outside="closeDropDown" :class="parentClass">
    <slot name="title">
      <a class="dropdown-toggle" data-toggle="dropdown" role="button">
        <i :class="icon" />
        <p class="notification">
          {{ title }}
          <b class="caret" />
        </p>
      </a>
    </slot>
    <slot name="body"></slot>
  </div>
</template>
<script>
export default {
  name: "DropDownSort",
  props: {
    direction: {
      type: String,
      default: "down",
    },
    title: String,
    icon: String,
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.active,
    };
  },
  computed: {
    parentClass() {
      return [
        { open: this.active },
        { dropdown: this.direction === "down" },
        { dropup: this.direction === "up" },
      ];
    },
  },
  methods: {
    closeDropDown() {
      this.$emit("update-close");
    },
  },
};
</script>

import ApiUserProfile from "@/api/methods/ApiUserProfile";
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";
import SubscriptionStatus from "@/enums/SubscriptionStatus";
import {UserDataHelper} from "@/api/helpers";

export default {
    namespaced: true,
    state: {
        user: UserDataHelper.getUser(),
        token: sessionStorage.getItem("token")
    },
    getters: {
        checkForActiveSubscription: (state, getters, rootState, rootGetters) => {
            return state.user &&
                rootGetters["SubscriptionAndAddons/checkSubscriptionStatus"](SubscriptionStatus.active) &&
                !rootGetters["SubscriptionAndAddons/checkSubscriptionStatus"](SubscriptionStatus.expired) &&
                !rootGetters["SubscriptionAndAddons/checkSubscriptionStatus"](SubscriptionStatus.cancelled);
        },
    },
    actions: {
        setToken({commit}, token) {
            commit("setToken", token);
        },
        setUserData({commit}, userData) {
            commit("setUserData", userData);
        },
        async getUserDetails({state}) {
            if (!state.token) {
                return;
            }
            return ApiUserProfile.getUserDetails(state.token);
        },
        async getUserData({state, commit, dispatch}) {
            if (!state.token) {
                return;
            }
            commit("setToken", state.token);
            await dispatch("getUserDetails")
                .then((response) => {
                    const userDataToSave = response.data;
                    commit("setUserData", userDataToSave);
                    const period = state.user && state.user.subscriptionPeriod ? state.user.subscriptionPeriod : SubscriptionPeriod.annually.value;
                    dispatch('SubscriptionAndAddons/setPeriod', SubscriptionPeriod[period], {root: true});
                })
                .catch((e) => {
                    console.error("error+" + e.message);
                });
        }
    },
    mutations: {
        setUserData(state, userData) {
            state.user = userData;
            if (userData !== null) {
                sessionStorage.setItem("user", JSON.stringify(state.user));
            } else {
                sessionStorage.removeItem("user");
            }
        },
        setToken(state, token) {
            state.token = token;
            if (token !== null) {
                sessionStorage.setItem("token", token);
            } else {
                sessionStorage.removeItem("token");
            }
        }
    }
};

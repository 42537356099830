import Vue from "vue";
import {DataFormatter} from "@/api/helpers";

class GoogleMapToolTipHelper extends Vue {
    mapStyles() {
        return [
            {
                featureType: "water",
                stylers: [{saturation: 43}, {lightness: -11}, {hue: "#0088ff"}],
            },
            {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [{hue: "#ff0000"}, {saturation: -100}, {lightness: 99}],
            },
            {
                featureType: "poi.park",
                elementType: "geometry.fill",
                stylers: [{color: "#ccdca1"}],
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{color: "#767676"}],
            },
            {
                featureType: "road",
                elementType: "labels.text.stroke",
                stylers: [{color: "#ffffff"}],
            },
            {featureType: "poi", stylers: [{visibility: "off"}]},
            // The parameter responsible for the green color of the continents
            // {
            //   featureType: "landscape.natural",
            //   elementType: "geometry.fill",
            //   stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
            // },
            {featureType: "poi.park", stylers: [{visibility: "on"}]},
            {
                featureType: "poi.sports_complex",
                stylers: [{visibility: "on"}],
            },
            {featureType: "poi.medical", stylers: [{visibility: "on"}]},
            {
                featureType: "poi.business",
                stylers: [{visibility: "simplified"}],
            },
        ];
    }

    labels(item) {
        return {
            yield: item.yield || "N/A",
            itb: item.itb || "N/A",
            itv: item.currentITV == null || item.currentITV.data == null ? "N/A" : item.currentITV.data,
            ltv: item.currentLTV == null || item.currentLTV.data == null ? "N/A" : item.currentLTV.data,
            noteAddress: item.noteAddress || "N/A",
            noteTypeName: item.noteTypeName || "N/A",
            notePosition: item.notePosition || "N/A",
            unpaidBalance: item.unpaidBalance != null ? DataFormatter.currency(item.unpaidBalance) : "N/A",
            marketValue: item.marketValue == null || item.marketValue.data == null ? "N/A" : DataFormatter.currencyStringToNumber(item.marketValue.data),
            interestRateInitial: item.interestRateInitial || "N/A",
            companyOwnership: item.companyOwnership || "N/A",
            notePrice: DataFormatter.currency(item.notePrice) || "N/A",
            noteId: item.noteId,
            noteCode: item.noteCode,
        };
    }

    setPath(token) {
        var redirectUrl = null;
        if (token) {
            redirectUrl =
                process.env.VUE_APP_API_URL + "/#!/rout/" + encodeURIComponent(token);
        } else {
            redirectUrl = null;
        }
        return redirectUrl;
    }

    dashboardPath(item, token, detailsPath, tabId, previousState) {
        let redirectUrl = this.setPath(token);
        var noteDetailsPath = redirectUrl + detailsPath + item.noteCode + "&tabId=" + tabId + "&previousState=" + previousState;
        return noteDetailsPath;
    }
}

export default new GoogleMapToolTipHelper();

import MenuHeader from "@/layout/MenuHeader.vue";
import MainFooter from "@/layout/MainFooter.vue";
import {OffersPaths} from "@/router/const/OffersPaths";

const offersRoutes = [
    {
        path: OffersPaths.sellerOffers.path,
        name: OffersPaths.sellerOffers.name,
        components: {
            default: () => import("@/views/OffersSeller.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    }, {
        path: OffersPaths.buyerOffers.path,
        name: OffersPaths.buyerOffers.name,
        components: {
            default: () => import("@/views/OffersBuyer.vue"),
            menu: MenuHeader,
            footer: MainFooter,
        },
        meta: {
            requiresAuth: true,
            hideForAuth: false,
        },
    },
];

export default offersRoutes;
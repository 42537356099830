import { Model } from "vue-mc";

class UserShort extends Model {
  options() {
    return {
      editable: true,
      mutateOnChange: true,
    };
  }

  defaults() {
    return {
      unreadNotificationsCount: 0, //2,
      subscriptionName: null, // "AlaCarte_291_1",
      subscriptionDisplayName: null, // "AlaCarte",
      subscriptionAutoRenewDate: null, //"02/18/2022", MM/dd/yyyy
      subscriptionIsTrial: false,
      subscriptionTrialExpDate: null,
      subscriptionCancellationDate: null,
      privilegeCodeList: null,
      privilegeTagList: null,
      subscriptionPeriod: null,
      userDisplayName: null,
      userRegularSubscriptionHasExpired: false,
      userTrialSubscriptionHasExpired: false,
      subscriptionStatus: null, // active //cancelled
      brandingLogo: null,
      userGroup: false,
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      unreadNotificationsCount: Number, //2,
      subscriptionName: String, //"AlaCarte_291_1",
      subscriptionDisplayName: String, //"AlaCarte",
      subscriptionIsTrial: Boolean,
      subscriptionTrialExpDate: String,
      subscriptionStatus: String,
      subscriptionPeriod: String,
      userDisplayName: String,
      userRegularSubscriptionHasExpired: Boolean,
      userTrialSubscriptionHasExpired: Boolean,
      brandingLogo: String,
      userGroup: Boolean,
    };
  }
}

export default UserShort;

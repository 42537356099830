<template>
  <div
    class="sidebar sidebar-relative"
    id="sidebar"
    :data-color="sidebarItemColor"
    :style="sidebarStyle"
  >
    <div class="logo">
      <div class="pt-0">
        <plan-options v-if="period" :period="period" @cangeplan="changePlan" />
      </div>
      <h5 class="simple-text my-0">
        {{ title }}
      </h5>
      <slot name="sidebar-logo-bottom" />
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot name="content"></slot>
      <slot></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
            @changed="$emit('changed')"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarItem from "./SidebarItem.vue";
import PlanOptions from "@/views/components/PlanOptions.vue";

export default {
  components: {
    SidebarItem,
    PlanOptions,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    period: {
      type: Object,
      default: null,
    },
    sidebarItemColor: {
      type: String,
      default: "#215a72",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    sidebarStyle() {
      return {};
    },
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
</style>

import http from "@/http-common";
import {
	CREATE_USER,
	RESET_PASSWORD,
	CHANGE_PASSWORD,
	RECALCULATE_USER_NOTE,
	APPLY_REGISTRATION_PROMO,
	LOGOUT,
	LOGIN,
	USER_DATA,
	RESIGN_AGREEMENT,
	GET_TRIAL_PRIVILEGES,
	USER_VERIFY,
	GET_FILTERED_USER_PAYMENT_HISTORY_COUNT,
	CREATE_API_KEY,
	GET_API_KEY_LIST,
	DELETE_API_KEY,
	GET_MANAGE_USER_GROUPS,
	JOIN_IN_USER_GROUP,
	LEAVE_FROM_USER_GROUP,
	REMOVE_USER_FROM_GROUP,
	ADD_USER_TO_GROUP,
	UPLOAD_USER_LOGO, GET_PRIVILEGE_FOR_REGISTRATION,
} from "@/api/Constants";
import Api from "@/api/methods/Api";

class ApiUser extends Api {
	createUser(data) {
		return http.post(CREATE_USER, data);
	}

	resetPassword(data) {
		return http.post(RESET_PASSWORD, data);
	}

	changePassword(data, token) {
		return http.post(CHANGE_PASSWORD + token, data);
	}

	recalculateUserNote(token) {
		return http.get(RECALCULATE_USER_NOTE, this.headers(token));
	}

	logout(token) {
		return http.get(LOGOUT, this.headers(token));
	}

	agree(token) {
		return http.get(RESIGN_AGREEMENT, this.headers(token));
	}

	getPaymentsCount(token) {
		return http.post(
			GET_FILTERED_USER_PAYMENT_HISTORY_COUNT,
			{},
			this.headers(token)
		);
	}

	getUserActualData(token) {
		return http.get(USER_DATA, this.headers(token));
	}

	getRefresh(token) {
		return http.get(USER_DATA, this.headers(token));
	}

	applyRegistrationPromoCode(code) {
		return http.post(APPLY_REGISTRATION_PROMO, code);
	}

	getPrivilegeForRegistration(needTrial) {
		return http.get(GET_PRIVILEGE_FOR_REGISTRATION + needTrial, this.headers);
	}

	verifyUser(verificationToken) {
		return http.get(USER_VERIFY + verificationToken);
	}

	getApiKey(userId, token) {
		return http.get(GET_API_KEY_LIST + userId, this.headers(token));
	}

	createApiKey(keyname, userId, token) {
		return http.get(
			CREATE_API_KEY + userId + "/" + keyname,
			this.headers(token)
		);
	}

	delApiKey(keyname, userId, token) {
		return http.delete(
			DELETE_API_KEY + userId + "/" + keyname,
			this.headers(token)
		);
	}

	login(name, pass) {
		const headers = {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			},
		};
		return http.post(
			LOGIN,
			{
				username: name,
				password: pass,
			},
			headers
		);
	}

	getManageUserGroupsModel() {
		return http.get(GET_MANAGE_USER_GROUPS);
	}

	joinInUserGroup(promoCode, token) {
		var promo = {
			promoCode: promoCode,
		};
		return http.post(JOIN_IN_USER_GROUP, promo, this.headers(token));
	}

	leaveFromUserGroup(groupId, token) {
		var groupModel = {
			groupId: groupId,
		};
		return http.post(LEAVE_FROM_USER_GROUP, groupModel, this.headers(token));
	}

	removeUserFromGroup(groupModel, token) {
		return http.post(REMOVE_USER_FROM_GROUP, groupModel, this.headers(token));
	}

	addUserToGroup(userList, token) {
		return http.post(ADD_USER_TO_GROUP, userList, this.headers(token));
	}

	uploadUserLogo(formData, token) {
		var headers = {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"X-Authorization": "Bearer " + token,
				"Content-Type": "multipart/form-data",
			},
		};
		return http.post(UPLOAD_USER_LOGO, formData, headers);
	}
	
	removeUserLogo() {
		return http.delete("api/removeUserLogo");
	}

	getListOfInvestorProfileCustomForAdminGroup() {
		return http.get("api/getListOfInvestorProfileCustomForAdminGroup");
	}

	getUserGroupHistory() {
		return http.get("api/getUserGroupHistory");
	}

	getUserGroupSettings() {
		return http.get("api/getUserGroupSettings");
	}

	saveMarketplaceMenuLabel(model) {
		return http.post("api/saveMarketplaceMenuLabel", model);
	}

	findNotesByInvestorProfile(buyerId) {
		return http.get("api/findNotesByInvestorProfile/" + buyerId);
	}
}

export default new ApiUser();

<template>
  <div
    class="sidebar-filter sidebar-filter-right"
    :class="{ 'sidebar-filter-open-right': isActive }"
  >
    <div class="title">
      <p class="for-sale-title for-sale-flex-center cyan-900 text-center">
        Search Filters
      </p>
      <md-button
        class="md-simple md-close md-just-icon m-0"
        @click="closeFilter"
      >
        <md-icon class="opacity-100">clear</md-icon>
      </md-button>
    </div>
    <div class="buttons-container">
      <md-button class="md-cyan-900 m-0" @click="onClearFiltersClick">
        {{ $t("button-text.reset") }}
      </md-button>
      <md-button class="md-danger m-0" @click="onResultsClick">
        {{ $t("button-text.apply") }}
        <badge
          v-if="count > 0"
          type="white"
          class="badge-filter d-flex align-self-start text-red"
        >
          <b>{{ count }}</b>
        </badge>
      </md-button>
    </div>
    <div class="overflow-y-auto expand-container">
      <div
        v-for="item in filterData"
        :key="item.label"
        :class="{
          'd-none':
            (item.name == 'n.noteTypeName' && isNoteType) ||
            (item.name == 'n.notePosition' && isNotePosition),
        }"
      >
        <p class="module-title fw-600 mb-0 mt-10" v-if="item.type != 'select'">
          {{ item.label }}
        </p>
        <md-field class="mb-0 pt-0" v-if="item.type == 'input'">
          <md-input
            v-model.trim="item.model"
            :placeholder="capitalize(item.label)"
            type="text"
            @keyup.enter="recalculateCounter"
            @blur="recalculateCounter"
          />
        </md-field>

        <div class="md-layout" v-if="item.type == 'input-range' && item.model">
          <div class="md-layout-item md-size-45">
            <md-field class="mb-0 pt-0">
              <md-input
                maxlength="15"
                md-counter="false"
                v-model.trim="item.model.from"
                v-number="number"
                placeholder="From"
                class="w-100"
                type="text"
                @keyup.enter="recalculateCounter"
                @blur="recalculateCounter"
              />
            </md-field>
          </div>
          <div class="md-layout-item md-size-10 align-self-end dash-padding">
            —
          </div>
          <div class="md-layout-item md-size-45">
            <md-field class="mb-0 pt-0">
              <md-input
                maxlength="15"
                md-counter="false"
                v-model.trim="item.model.to"
                v-number="number"
                placeholder="To"
                class="w-100"
                type="text"
                @keyup.enter="recalculateCounter"
                @blur="recalculateCounter"
              />
            </md-field>
          </div>
        </div>

        <ul
          class="list-unstyled m-0 pl-0 overflow-y-hidden"
          v-if="item.type == 'checkbox' && item.name == 'n.noteTypeName'"
        >
          <li class="my-0" v-for="item in noteTypes" :key="item.name">
            <md-checkbox
              class="md-checkbox-left mr-0 mb-0"
              v-model="item.selected"
              @change="onTypesChange"
            >
              {{ item.name }}
            </md-checkbox>
          </li>
        </ul>
        <ul
          class="list-unstyled m-0 pl-0 overflow-y-hidden"
          v-if="item.type == 'checkbox' && item.name == 'n.notePosition'"
        >
          <li class="my-0" v-for="item in notePositions" :key="item.name">
            <md-checkbox
              class="md-checkbox-left mr-0 mb-0"
              v-model="item.selected"
              @change="onPositionsChange"
            >
              {{ item.name }}
            </md-checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Badge } from "@/components";
import ForSaleHelper from "@/api/helpers/ForSaleHelper";
import { directive as VNumber } from "@coders-tm/vue-number-format";
export default {
  components: {
    Badge,
  },
  name: "SidebarFilter",
  props: {
    isActive: Boolean,
    filterData: Array,
    notePositions: Array,
    isNoteType: Boolean,
    isNotePosition: Boolean,
    filtersCount: Number,
    noteTypeList: Array,
    number: {
      decimal: ".",
      separator: ",",
      prefix: "",
      precision: 2,
    },
  },
  data() {
    var count = this.filtersCount;
    return {
      noteTypes: ForSaleHelper.noteTypes(),
      count,
    };
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal) {
          this.updateOnFly();
        }
      },
    },
    isNoteType: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateOnFly();
        }
      },
    },
    isNotePosition: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateOnFly();
        }
      },
    },
  },
  methods: {
    onTypesChange() {
      this.prepareResultsType();
      this.recalculateCounter();
    },
    onPositionsChange() {
      this.prepareResultsPosition();
      this.recalculateCounter();
    },
    prepareResultsType() {
      var results = [];
      if (this.noteTypes) {
        this.noteTypes.forEach((item) => {
          if (item.selected) {
            results.push(item.value);
          }
        });
      }
      if (this.filterData != null) {
        this.filterData.forEach((item) => {
          if (item.name == "n.noteTypeName") {
            item.model = results;
          }
        });
      }
    },
    prepareResultsPosition() {
      var results = [];
      if (this.notePositions) {
        this.notePositions.forEach((item) => {
          if (item.selected) {
            results.push(item.value);
          }
        });
      }
      if (this.filterData != null) {
        this.filterData.forEach((item) => {
          if (item.name == "n.notePosition") {
            item.model = results;
          }
        });
      }
    },
    updateOnFly() {
      this.initSavedDataPosition();
      this.initSavedDataType();
      this.recalculateCounter();
      this.$forceUpdate();
    },
    initSavedDataPosition() {
      var results = [];
      if (this.filterData != null) {
        this.filterData.forEach((item) => {
          if (item.name == "n.notePosition") {
            results = item.model;
          }
        });
      }
      if (this.notePositions) {
        this.notePositions.forEach((item) => {
          if (results.includes(item.value)) {
            item.selected = true;
          }
        });
      }
    },
    initSavedDataType() {
      var results = [];
      if (this.filterData != null) {
        this.filterData.forEach((item) => {
          if (item.name == "n.noteTypeName") {
            results = item.model;
          }
        });
      }
      if (this.noteTypes) {
        this.noteTypes.forEach((item) => {
          if (results.includes(item.value)) {
            item.selected = true;
          }
        });
      }
    },
    recalculateCounter() {
      this.count = ForSaleHelper.filtersCountForMedia(
        this.filterData,
        this.isNoteType,
        this.isNotePosition
      );
    },
    capitalize(word) {
      return ForSaleHelper.capitalize(word);
    },
    closeFilter() {
      this.$emit("close-filter");
      this.$emit("clear-pagination");
      this.$emit("search-by", 1);
      this.$emit("update-top");
    },

    clearInputs() {
      if (this.noteTypes) {
        this.noteTypes.forEach((item) => {
          item.selected = null;
        });
      }
      if (this.notePositions) {
        this.notePositions.forEach((item) => {
          item.selected = null;
        });
      }
    },
    setSavedValue(name, model) {
      const filterData = this.filterData;
      Object.entries(filterData).map(([key, value]) => {
        if (filterData[key]["name"] == name) {
          value.model = model;
        }
      });
    },

    onResultsClick() {
      this.$emit("close-filter");
      this.$emit("clear-pagination");
      this.$emit("search-by", 1);
      this.$emit("update-top");
    },
    updateList() {
      this.noteTypes = this.noteTypeList;
      this.initSavedDataType();
    },
    onClearFiltersClick() {
      let filterStr = sessionStorage.getItem("filterBy");
      if (filterStr && typeof filterStr != "undefined") {
        sessionStorage.removeItem("filterBy");
      }
      this.count = 0;
      this.$emit("close-filter");
      this.$emit("init-filter");
      this.$emit("clear-pagination");
      this.$emit("search-by", 1);
      this.$emit("update-top");
      window.dispatchEvent(
        new CustomEvent("clear-local-data", {
          detail: {
            storage: null,
          },
        })
      );
    },
  },
  created() {
    this.initSavedDataPosition();
    this.initSavedDataType();
  },
  mounted() {
    window.addEventListener("clear-local-data", this.clearInputs);
    window.addEventListener("on-list-updated", this.updateList);
  },
  beforeDestroy() {
    window.removeEventListener("clear-local-data", null);
    window.removeEventListener("on-list-updated", null);
  },
  directives: {
    number: VNumber,
  },
};
</script>

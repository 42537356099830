import { DropDown } from "../components";
import { Parallax } from "../components";
import { SidebarPlugin } from "../components";
import { VPopover } from "v-tooltip";
import { Carousel, CarouselItem } from "element-ui";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("drop-down", DropDown);
    Vue.component(Parallax.name, Parallax);
    Vue.component("v-popover", VPopover);
    Vue.component("DataTable", DataTable);
    Vue.component("DataView", DataView);
    Vue.component("Column", Column);
    Vue.component("ColumnGroup", ColumnGroup);
    Vue.component("Button", Button);
    Vue.component("Dropdown", Dropdown);
    Vue.component("Paginator", Paginator);
    Vue.use(SidebarPlugin);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    // Vue.use(VueAuth, {
    //   authPath: ROUTER_LINKS.login.path,
    //   storagePrefix: '_noteunlimited.',
    //   redirectType: 'browser'
    // });
  },
};

export default GlobalComponents;

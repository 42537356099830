<template>
  <div>
    <div
      :class="[
        'md-collapse',
        { 'is-active': this.isActive },
        { [getColorExpand(colorExpand)]: true },
      ]"
    >
      <md-card class="mb-0" :class="cardColorExpand" v-if="!cardPlain">
        <div
          class="md-collapse-label w-100"
          :md-ripple="mdRipple"
          @click="toggle()"
        >
          <p class="md-collapse-title">
            {{ title }}
            <md-icon>{{ icon }}</md-icon>
          </p>
        </div>
      </md-card>
      <div
        v-if="cardPlain"
        class="md-collapse-label w-100"
        :md-ripple="mdRipple"
        @click="toggle()"
      >
        <p class="md-collapse-title">
          {{ title }}
          <md-icon>{{ icon }}</md-icon>
        </p>
      </div>

      <collapse-transition :duration="350">
        <div v-show="isActive" class="md-collapse-content">
          <slot name="md-expand-pane" />
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "Expand",
  components: {
    CollapseTransition,
  },
  props: {
    title: String,
    expand: {
      type: Boolean,
      default: false,
    },
    icon: String,
    colorExpand: String,
    cardColorExpand: String,
    cardPlain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: true,
      mdRipple: false,
    };
  },
  created() {
    this.isActive = this.expand;
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
    },
    getColorExpand(colorExpand) {
      return "md-" + colorExpand + "";
    },
  },
};
</script>

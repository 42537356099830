<template>
  <div class="pdf-content">
    <div class="report-container">
      <slot name="pageContent" />
    </div>
    <div class="report-info">
      <div class="pull-right">
        Powered by Noteunlimited Inc.
        <img class="summary-report-logo-powered" v-bind:src="logoImage" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PdfPage",
  props: {
    logoImage: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    margin: 0;
  }
  div.pdf-content {
    page-break-after: always;
  }
}
.pdf-content {
  margin-top: 20px;
  width: 50vw !important;
  height: 70vh !important;
  background: #fff;
}

.report-info {
  float: right !important;
  display: flex;
  padding: 20px;
  padding-bottom: 5px;
  font-size: 16px !important;
  line-height: calc(20px / 16px);

  .summary-report-logo-powered {
    height: 1cm;
  }
}

.report-container {
  width: 100%;
  height: calc(28.7cm - 1cm - 5px);
  padding: 20px;
  pointer-events: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}
</style>

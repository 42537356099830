import Vue from "vue";

class AlacarteHelper extends Vue {
  randColor(index) {
    let i = index % 3;
    const colors = ["cyan", "yellow", "red"];
    return colors[i];
  }

  prepareLinks(categories) {
    var cats = categories.map(function (cat) {
      return {
        name: cat.name,
        path: "/alacarte?category=" + cat.categoryId,
        icon: cat.icon ? cat.icon : "dashboard",
        badge: cat.badge ? cat.badge : 0,
      };
    });
    cats.push({
      name: "To bundle",
      path: "/plans",
      icon: "arrow_back",
      badge: 0,
    });
    return cats;
  }

  prepareCart(alacarte, period) {
    var res = [];
    alacarte.categories.forEach((category, i) => {
      category.badge = 0;
      if (category.privileges) {
        category.privileges.forEach((priv, i) => {
          if (
            alacarte.unpaidAlaCarteSubscription.subscriptionPrivilegesGroupIdList.indexOf(
              priv.privilegeId
            ) != -1
          ) {
            category.badge = category.badge + 1;
            res.push({
              id: priv.privilegeId,
              name: priv.privilegeName,
              price: priv[period.priceKeySubscription],
            });
          }
        });
      }
    });

    return res;
  }

  removeItem(arr, id) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === id) {
        arr.splice(i, 1);
        i--;
      }
    }
  }

  total(arr) {
    return 908;
  }
}

export default new AlacarteHelper();

<template>
    <div class="modal modal-bg" :class="{ show: open }">
        <modal v-if="open" modal-with-card card-classes="md-card-login" type="modal-signup" @close="closeModal" >
            <template #header>
                <md-card-header class="md-card-header-cyan m-0">
                    <md-button class="md-simple md-close md-white md-just-icon md-round modal-card-button mt-10" @click="closeModal" >
                        <md-icon class="opacity-100">{{ $t("icon.clear") }}</md-icon>
                    </md-button>
                    <h4 class="card-title mt-0">Notifications</h4>
                </md-card-header>
            </template>

            <template #body>
                <div class="notifications-modal-container">
                    <md-table v-if="visible" v-model="visible.models" @md-selected="onSelect" :class="tableMarginTop">
                        <md-table-toolbar class="position-absolute notifications-modal-padding-top pt-0">
                            <div class="md-toolbar-row">
                                <div class="md-toolbar-section-start">
                                    <md-button class="md-just-icon my-0" :class="classesForIcon" @click="refreshNotifications" :disabled="isDisabledBtns">
                                        <md-icon>{{ $t("icon.refresh") }}</md-icon>
                                        <md-tooltip md-direction="top">Refresh</md-tooltip>
                                    </md-button>
                                    <md-button class="md-just-icon my-0" :class="classesForIcon"
                                        id="deleteNotifications" :style="{ display: 'none' }" @click="deleteNotifications" :disabled="isDisabledBtns">
                                        <md-icon>{{ $t("icon.trash") }}</md-icon>
                                        <md-tooltip md-direction="top">Delete notification(s)</md-tooltip>
                                    </md-button>
                                    <md-button class="md-just-icon my-0" :class="classesForIcon"
                                        id="markAsRead" :style="{ display: 'none' }" @click="markAsReadMultiply" :disabled="isDisabledBtns">
                                        <md-icon class="material-icons-outlined">{{$t("icon.drafts")}}</md-icon>
                                        <md-tooltip md-direction="top">Mark as read</md-tooltip>
                                    </md-button>
                                    <md-button class="md-just-icon" :class="classesForIcon"
                                        id="markAsUnread" :style="{ display: 'none' }" @click="markAsUnReadMultiply" :disabled="isDisabledBtns" >
                                        <md-icon class="material-icons-outlined"> {{ $t("icon.unread") }} </md-icon>
                                        <md-tooltip md-direction="top">Mark as unread</md-tooltip>
                                    </md-button>
                                </div>
                            </div>
                        </md-table-toolbar>
                        <template #md-table-row="{ item }">
                            <md-table-row v-if="item.display != null" md-selectable="multiple" :class="item.display == LogMarker.read ? 'fw-400' : 'fw-bold'">
                                <md-table-cell>
                                    <span>{{item.message}}</span>
                                    <span v-if="item.linkText && item.linkClickMethod">&nbsp;&nbsp;
                                        <a @click="notificationLinkClick(item.linkClickMethod)" class="dev-link pointer">{{item.linkText}}</a>
                                    </span>
                                </md-table-cell>
                                <md-table-cell>{{ item.dateTime }}</md-table-cell>
                                <md-table-cell>
                                    <md-button class="md-just-icon" :class="classesForIcon" @click="deleteNotification(item)">
                                        <md-icon>{{ $t("icon.trash") }}</md-icon>
                                        <md-tooltip md-direction="top">Delete</md-tooltip>
                                    </md-button>
                                    <md-button v-if="item.display == LogMarker.unread" class="md-just-icon" :class="classesForIcon" @click="markAsRead(item)">
                                        <md-icon class="material-icons-outlined">{{ $t("icon.drafts") }}</md-icon>
                                        <md-tooltip md-direction="top">Mark as read</md-tooltip>
                                    </md-button>
                                    <md-button v-if="item.display == LogMarker.read" class="md-just-icon" :class="classesForIcon" @click="markAsUnRead(item)">
                                        <md-icon class="material-icons-outlined">{{ $t("icon.unread") }}</md-icon>
                                        <md-tooltip md-direction="top">Mark as unread</md-tooltip>
                                    </md-button>
                                </md-table-cell>
                            </md-table-row>
                        </template>
                    </md-table>
                </div>
            </template>

            <template slot="footer"></template>
        </modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ApiUsersLogs from "@/api/methods/ApiUsersLogs";
import {VTooltip} from "v-tooltip";
import UsersLogModel from "@/api/models/user/UsersLogModel";
import {Collection} from "vue-mc";
import Mixins from "@/plugins/basicMixins";
import {ErrorHelper} from "@/api/helpers";
import {REDIRECT_LINKS, ROUTER_LINKS} from "@/plugins/Constants";
import {OffersPaths} from "@/router/const/OffersPaths";

export const LogMarker = Object.freeze({
    delete: null,
    read: "read",
    unread: "unread",
});

export default {
    name: "ModalNotification",
    components: {Modal},
    mixins: [Mixins.ShowToastr, Mixins.Loading, Mixins.SetPath],
    props: {
        open: Boolean,
    },
    watch: {
        open: {
            immediate: true,
            handler(value) {
                if (value == true) {
                    this.enableButtons();
                    this.getLogs();
                }
            },
        },
    },
    data() {
        return {
            LogMarker: LogMarker,
            disableBtn: false,
            classesForIcon: [
                "md-cyan-900",
                "md-simple",
                window.innerWidth <= 576 ? "md-sm" : "md-lg",
            ],
            tableMarginTop: [window.innerWidth <= 576 ? "mt-0" : ""],
            data: new Collection([], {model: UsersLogModel}),
            visible: null,
        };
    },
    computed: {
        isDisabledBtns() {
            return this.disableBtn;
        },
    },
    methods: {
        getLogs() {
            this.showLoading();
            let token = sessionStorage.getItem("token");
            if (!token) {
                this.closeModal();
                return;
            }
            ApiUsersLogs.getDisplayedUserLogs(token)
                .then((response) => {
                    this.data = new Collection(response.data, {model: UsersLogModel});
                    this.visible = this.data.filter((item) => {
                        return item.display != null;
                    });
                    if (this.visible.models.length == 0) {
                        this.enableButtons();
                        this.closeModal();
                    }
                    this.hideLoading();
                })
                .catch((e) => {
                    this.hideLoading();
                    this.enableButtons();
                    this.closeModal();
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        closeModal() {
            this.$emit("close");
        },
        onSelect(items) {
            this.visible.each((model, index) => {
                model.selected = false;
                model.actionNeeded = false;
            });
            let markAsReadEl = document.getElementById("markAsRead");
            let deleteNotificationsElem = document.getElementById(
                "deleteNotifications"
            );
            if (items.length > 0) {
                markAsReadEl.style.display = deleteNotificationsElem.style.display =
                    "block";
            } else {
                markAsReadEl.style.display = deleteNotificationsElem.style.display =
                    "none";
            }
            items.forEach((item) => {
                item.selected = true;
                item.actionNeeded = true;
            });
        },
        deleteNotification(item) {
            item.display = null;
            item.actionNeeded = true;
            this.save();
        },
        markAsRead(item) {
            item.display = LogMarker.read;
            item.actionNeeded = true;
            this.save();
        },
        markAsUnRead(item) {
            item.display = LogMarker.unread;
            item.actionNeeded = true;
            this.save();
        },
        refreshNotifications() {
            this.getLogs();
        },
        disableButtons() {
            this.disableBtn = true;
        },
        enableButtons() {
            this.disableBtn = false;
        },
        save() {
            let token = sessionStorage.getItem("token");
            if (token) {
            } else {
                this.closeModal();
                return;
            }
            let toSave = this.visible.filter((item) => {
                return item.actionNeeded == true;
            });
            if (toSave.models.count <= 0) {
                return;
            }
            this.disableButtons();

            ApiUsersLogs.markUserLogs(
                token,
                toSave.models.map(function (item) {
                    if (item.display == "null" || item.display == LogMarker.delete) {
                        return {
                            display: null,
                            userLogId: item.userLogId,
                        };
                    }
                    return {
                        display: item.display,
                        userLogId: item.userLogId,
                    };
                })
            )
                .then((_) => {
                    this.enableButtons();
                    this.getLogs();
                })
                .catch((e) => {
                    this.enableButtons();
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        deleteNotifications() {
            this.visible.each((model, index) => {
                if (model.selected) {
                    model.display = LogMarker.delete;
                    model.actionNeeded = true;
                }
            });
            this.save();
        },
        markAsReadMultiply() {
            this.visible.each((model, index) => {
                if (model.selected) {
                    model.display = LogMarker.read;
                    model.actionNeeded = true;
                }
            });
            this.save();
        },
        markAsUnReadMultiply() {
            this.visible.each((model, index) => {
                if (model.selected) {
                    model.display = LogMarker.unread;
                    model.actionNeeded = true;
                }
            });
            this.save();
        },
        notificationLinkClick(linkClickMethod){
            if (linkClickMethod && typeof this[linkClickMethod] === "function") {
                this[linkClickMethod]();
            }
        },
        /* important: do not delete! method called by name */
        viewOffersBuyerLinkClick(){
            this.closeModal();
            this.$router.push(OffersPaths.buyerOffers.path).catch(err => {this.$router.go(); });
        },
        /* important: do not delete! method called by name */
        viewOffersSellerLinkClick(){
            this.closeModal();
            this.$router.push(OffersPaths.sellerOffers.path).catch(err => {this.$router.go(); });
        }
    },
    mounted() {
        // this.getLogs();
        VTooltip.options.autoHide = false;
    },
};
</script>

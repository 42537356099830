export default {
  namespaced: true,
  state: {
    signInUpModalActive: false
  },
  actions: {
    setModalActiveStatus ({commit}, isActive) {
      commit("setModalActiveStatus", isActive);
      if (isActive) {
        disableScroll()
      } else {
        enableScroll();
      }
    }
  },
  mutations: {
    setModalActiveStatus (state, isActive) {
      state.signInUpModalActive = isActive;
    },
  }
};

const disableScroll = () => {
  document.body.classList.add("overflow-y-hidden");
};
const enableScroll = () => {
  document.body.classList.remove("overflow-y-hidden");
};
import ApiPayment from "@/api/methods/ApiPayment";
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";

export const urlParamsMap = {
    subscription: "s",
    addons: "a",
    promoCode: "p",
    period: "sp"
};

export default {
    namespaced: true,
    state: {
        paymentData: JSON.parse(localStorage.getItem("payment")),
        loginPaymentData: {
            [urlParamsMap.promoCode]: null,
            [urlParamsMap.subscription]: null,
            [urlParamsMap.addons]: [null],
            [urlParamsMap.period]: SubscriptionPeriod.annually.query
        },
        promoCode: sessionStorage.getItem("promoCode"),
        promoInfo: null,
        paramsForPaymentSystems: null
    },
    getters: {
        getTotalPriceWithDiscount: (state) => () => {
            const addonsPrice = state.promoInfo.chargesPricesWithDiscount
                .reduce((acc, curr) => {
                    acc = acc + curr.priceWithDiscount;
                    return acc;
                }, 0);
            const subscriptionPrice = state.promoInfo.subscriptionWithDiscount;
            return addonsPrice + subscriptionPrice;
        },
        checkLoginPaymentDataIsEmpty: (state) => () => {
            if (state.loginPaymentData && (state.loginPaymentData[urlParamsMap.promoCode] || state.loginPaymentData[urlParamsMap.subscription] ||
                (state.loginPaymentData[urlParamsMap.addons] && state.loginPaymentData[urlParamsMap.addons].length > 0 && state.loginPaymentData[urlParamsMap.addons][0]))) {
                return false;
            }
            return true;
        },
        getPromoFromPaymentUrl: (state) => () => {
            if (state.loginPaymentData && state.loginPaymentData[urlParamsMap.promoCode] && state.loginPaymentData[urlParamsMap.promoCode].length > 0 &&
                state.loginPaymentData[urlParamsMap.promoCode]) {
                return state.loginPaymentData[urlParamsMap.promoCode];
            }
            return null;
        },
    },
    actions: {
        checkUrlParams({commit}) {
            if (!window.location.href.includes("?")) {
                return;
            }
            const urlParams = new URLSearchParams(window.location.href.split('?').pop());
            if (urlParams.size === 0) {
                commit("setLoginPaymentData", null);
                commit("setPromoCode", null);
                return;
            }
            const loginPaymentData = {};
            for (const [key, value] of urlParams) {
                loginPaymentData[key] = value.includes(",") ? value.split(",") : value;
            }
            commit("setLoginPaymentData", loginPaymentData);
            commit("setPromoCode", loginPaymentData[urlParamsMap.promoCode]);
        },
        async getExtendedData({commit, state}) {
            if (!state.loginPaymentData) {
                return null;
            }
            const subscription = state.loginPaymentData[urlParamsMap.subscription];
            const promoCode = state.loginPaymentData[urlParamsMap.promoCode];
            if (!subscription) {
                return null;
            }
            let preparedAddons = state.loginPaymentData[urlParamsMap.addons];
            let addonsWithQuantity = [];
            if (typeof(preparedAddons) == "string") {
                preparedAddons = [preparedAddons];
            }
            preparedAddons.forEach((strAddonWithQuantity) => {
                if (strAddonWithQuantity) {
                    let addonsWithQuantityItem = strAddonWithQuantity.includes("|") ? strAddonWithQuantity.split("|") : strAddonWithQuantity;
                    if (addonsWithQuantityItem && addonsWithQuantityItem.length > 0) {
                        const addonItem = {
                            privilegeId: typeof(addonsWithQuantityItem) == "string"? addonsWithQuantityItem : addonsWithQuantityItem[0]
                        };
                        if (addonsWithQuantityItem.length == 2 && !!addonsWithQuantityItem[1]) {
                            addonItem.qty = parseInt(addonsWithQuantityItem[1]);
                        } else {
                            addonItem.qty = 1;
                        }
                        if (addonItem.qty < 0) {
                            addonItem.qty = 0;
                        } else if (addonItem.qty > 99) {
                            addonItem.qty = 99;
                        }
                        addonsWithQuantity.push(addonItem);
                    }
                }
            });

            let addons = addonsWithQuantity.map(item => item.privilegeId);
            addons = addons.filter(element => element !== null);
            const period = state.loginPaymentData[urlParamsMap.period];
            const params = {
                promoCode: promoCode,
                subscriptionPeriod: period,
                privilegesGroupsIdList: [subscription, ...addons]
            };
            return await ApiPayment.getExtendedDataForSubscription(params)
                .then((response) => {
                    if (!response) return null;
                    const addons = response.data.addons.map((addon) => {
                        let addonWithQty = addonsWithQuantity.filter(el => el.privilegeId == addon.privilegeId);
                        addon.qty = addonWithQty ? addonWithQty[0].qty : 1;
                        return addon;
                    });
                    const paymentData = {
                        addons: addons,
                        group: response.data.subscription,
                        period: SubscriptionPeriod[period],
                        paramsForPaymentSystems: response.data.paramsForPaymentSystems,
                        type: "Bundle",
                        plan: "Personal",
                    };
                    commit("setPaymentData", paymentData);
                    localStorage.setItem("payment", JSON.stringify(paymentData));
                    commit("setParamsForPaymentSystems", response.data.paramsForPaymentSystems);
                    return paymentData;
                });
        },
        setLoginPaymentData({commit}, data) {
            commit("setLoginPaymentData", data);
        },
        clearLoginPaymentData({commit}) {
            let data =  {
                [urlParamsMap.promoCode]: null,
                [urlParamsMap.subscription]: null,
                [urlParamsMap.addons]: [null],
                [urlParamsMap.period]: SubscriptionPeriod.annually.query
            };
            commit("setLoginPaymentData", data);
        },
        setPaymentData({commit}, data) {
            commit("setPaymentData", data);
        },
        setPromoInfo({commit}, info) {
            commit("setPromoInfo", info);
        },
        setPromoCode({commit}, data) {
            commit("setPromoCode", data);
        },
        setAddonQty({commit, state}, addon) {
            const newData = state.paymentData;
            const newAddon = newData.addons.find((oldAddon) => oldAddon.privilegeId === addon.privilegeId);
            newAddon.qty = addon.qty;
            commit("setPaymentData", newData);
        },
        getAddonsWithQuantity({commit, state}, addonsWithQuantityItem) {
            const addonItem = {};
            if (addonsWithQuantityItem && addonsWithQuantityItem.length > 0) {
                addonItem.privilegeId =  typeof(addonsWithQuantityItem) == "string"? addonsWithQuantityItem : addonsWithQuantityItem[0];
                if (addonsWithQuantityItem.length == 2 && !!addonsWithQuantityItem[1]) {
                    addonItem.qty = parseInt(addonsWithQuantityItem[1]);
                } else {
                    addonItem.qty = 1;
                }
            }
            return addonItem;
        }
    },
    mutations: {
        setPromoInfo(state, info) {
            state.promoInfo = info;
        },
        setPaymentData(state, data) {
            state.paymentData = data;
        },
        setLoginPaymentData(state, data) {
            state.loginPaymentData = state.loginPaymentData ? Object.assign(state.loginPaymentData, data) : null;
        },
        setPromoCode(state, code) {
            state.promoCode = code;
            if (code) {
                sessionStorage.setItem("promoCode", code);
            } else {
                sessionStorage.removeItem("promoCode");
            }
        },
        setParamsForPaymentSystems(state, params) {
            state.paramsForPaymentSystems = params;
        }
    }
};
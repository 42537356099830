import Api from "@/api/methods/Api";
import http from "@/http-common";
import {GET_EXTENDED_DATA_FOR_SUBSCRIPTION} from "@/api/Constants";

class ApiPayment extends Api {
  getExtendedDataForSubscription(params) {
    const token = sessionStorage.getItem("token");
    if (!token) return new Promise((resolve) => resolve(null));
    return http.post(GET_EXTENDED_DATA_FOR_SUBSCRIPTION, params, this.headers(token));
  }
}

export default new ApiPayment();
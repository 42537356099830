<template>
  <!-- Big White Footer V2 -->
  <footer
    class="footer footer-white footer-big shadow-sm"
    :class="{ [`footer-${type}`]: type }"
    data-background-color="black"
  >
    <cookie-law theme="mytheme">
      <div slot-scope="props" class="d-flex">
        <div class="Cookie--mytheme--content-wrapper">
          <div slot="message" class="Cookie--mytheme--content">
            {{ $t("cookies-notification.title") }}
            <md-link
              :to="routing.cookies"
              md-type="link"
              :text="$t('cookies-notification.link-cookies')"
            ></md-link>
          </div>
        </div>
        <div class="Cookie--mytheme--button-wrapper">
          <button class="Cookie--mytheme--button" @click="props.accept">
            <span>{{ $t("button-text.accept") }}</span>
          </button>
          <button
            class="Cookie--mytheme--button Cookie--mytheme--button-decline"
            @click="props.close"
          >
            <span>{{ $t("button-text.refuse") }}</span>
          </button>
        </div>
      </div>
    </cookie-law>
    <div class="container">
      <div class="content">
        <!-- <div class="md-layout">
          <div
            class="md-layout-item md-xlarge-size-30 md-large-size-30 md-medium-size-25 md-small-size-15 md-xsmall-hide"
          ></div>
          <div
            class="md-layout-item md-xlarge-size-10 md-large-size-10 md-medium-size-15 md-small-size-20 md-xsmall-size-100"
          >
            <ul class="links-vertical">
              <li id="about">
                <md-link
                  :to="routing.about"
                  md-type="link"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                  :text="$t('footer.about')"
                ></md-link>
              </li>
              <li id="help">
                <md-link
                  :href="link_footer.help"
                  :text="$t('footer.help')"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                ></md-link>
              </li>
              <li id="blog">
                <md-link
                  :href="link_footer.blog"
                  :text="$t('footer.blog')"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                ></md-link>
              </li>
            </ul>
          </div>
          <div
            class="md-layout-item md-xlarge-size-15 md-large-size-15 md-medium-size-20 md-small-size-25 md-xsmall-size-100"
          >
            <ul class="links-vertical">
              <li id="announcements">
                <md-link
                  :to="routing.announcements"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                    'd-inline-block': true
                  }"
                  md-type="link"
                  :text="$t('footer.announcements')"
                ></md-link>
                <badge
                  type="danger"
                  class="badge-footer align-top"
                  v-if="hasNew"
                >
                  NEW
                </badge>
              </li>
              <li id="contact_us">
                <md-link
                  :to="routing.contact"
                  md-type="link"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                  :text="$t('footer.contact')"
                ></md-link>
              </li>

              <li id="feedback">
                <md-link
                  :to="routing.feedback"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                  md-type="link"
                  :text="$t('footer.feedback')"
                ></md-link>
              </li>
            </ul>
          </div>
          <div
            class="md-layout-item md-xlarge-size-20 md-large-size-20 md-medium-size-30 md-small-size-30 md-xsmall-size-100"
          >
            <ul class="links-vertical">
              <li id="terms">
                <md-link
                  :to="routing.terms"
                  md-type="link"
                  :text="$t('footer.terms')"
                ></md-link>
              </li>
              <li id="developers">
                <md-link
                  :to="routing.developers"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                  md-type="link"
                  :text="$t('footer.developers')"
                ></md-link>
              </li>
              <li id="vendor_сontact">
                <md-link
                  :to="routing.vendorContact"
                  md-type="link"
                  :md-class="{
                    'inactive-link opacity-60': AgreementStore.isExpired,
                  }"
                  :text="$t('footer.vendorContact')"
                ></md-link>
              </li>
            </ul>
          </div>
          <div
            class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-hide md-xsmall-hide"
          ></div>

        </div> -->
        <div class="md-layout">
          <div
            class="d-flex flex-row-not-xsmall mx-auto-not-xsmall"
          >
            <div class="d-flex mx-20">
              <ul class="links-vertical">
                <li id="about">
                  <md-link
                    :to="routing.about"
                    md-type="link"
                    :text="$t('footer.about')"
                  ></md-link>
                </li>
                <li id="help">
                  <md-link
                    :href="link_footer.help"
                    :text="$t('footer.help')"
                  ></md-link>
                </li>
                <li id="blog">
                  <md-link
                    :href="link_footer.blog"
                    :text="$t('footer.blog')"
                  ></md-link>
                </li>
              </ul>
            </div>
            <div class="d-flex mx-20">
              <ul class="links-vertical">
                <li id="announcements">
                  <md-link
                    :to="routing.announcements"
                    md-class="d-inline-block"
                    md-type="link"
                    :text="$t('footer.announcements')"
                  ></md-link>
                  <badge
                    type="danger"
                    class="badge-footer align-top"
                    v-if="hasNew"
                  >
                    NEW
                  </badge>
                </li>
                <li id="contact_us">
                  <md-link
                    :to="routing.contact"
                    md-type="link"
                    :text="$t('footer.contact')"
                  ></md-link>
                </li>

                <li id="feedback">
                  <md-link
                    :to="routing.feedback"
                    md-type="link"
                    :text="$t('footer.feedback')"
                  ></md-link>
                </li>
              </ul>
            </div>
            <div class="d-flex mx-20">
              <ul class="links-vertical">
                <li id="terms">
                  <md-link
                    :to="routing.terms"
                    md-type="link"
                    :text="$t('footer.terms')"
                  ></md-link>
                </li>
                <li id="developers">
                  <md-link
                    :to="routing.developers"
                    md-type="link"
                    :text="$t('footer.developers')"
                  ></md-link>
                </li>
                <li id="vendor_сontact">
                  <md-link
                    :to="routing.vendorContact"
                    md-type="link"
                    :text="$t('footer.vendorContact')"
                  ></md-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="copyright">
        <ul class="social-buttons margin-footer-social-buttons">
          <li id="keep_in">
            <a class="md-simple inactive-link text-muted" role="button">
              <h6 class="my-0 lh-inherit">
                {{ $t("footer.keep-in-touch") }}
              </h6></a
            >
          </li>
          <li id="twitter">
            <div class="d-flex">
              <md-button
                :href="link_footer.twitter"
                rel="noopener noreferrer"
                target="_blank"
                class="md-twitter md-just-icon md-simple m-0"
              >
                <i class="fa-brands fa-square-x-twitter"></i>
                <md-tooltip md-direction="top">Twitter</md-tooltip>
              </md-button>
            </div>
          </li>
          <li id="facebook">
            <div class="d-flex">
              <md-button
                :href="link_footer.facebook"
                rel="noopener noreferrer"
                target="_blank"
                class="md-facebook md-just-icon md-simple m-0"
              >
                <i class="fa-brands fa-square-facebook"></i>
                <md-tooltip md-direction="top">Facebook</md-tooltip>
              </md-button>
            </div>
          </li>
          <li id="youtube">
            <div class="d-flex">
              <md-button
                :href="link_footer.youtube"
                rel="noopener noreferrer"
                target="_blank"
                class="md-youtube md-just-icon md-simple m-0"
              >
                <i class="fa-brands fa-youtube"></i>
                <md-tooltip md-direction="top">Youtube</md-tooltip>
              </md-button>
            </div>
          </li>
          <li>
            <div class="d-flex ml-10">
              &copy;{{ $t("footer.cool") }} {{ year }}{{ $t("footer.tail") }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  <!-- end Big White Footer V2 -->
</template>
<script>
import ApiAnnouncements from "@/api/methods/ApiAnnouncements";
import {
  TWITTER_LINK,
  YOUTUBE_LINK,
  FACEBOOK_LINK,
  WP_LINKS,
  ROUTER_LINKS,
} from "@/plugins/Constants";
import { Badge, MdLink } from "@/components";
import CookieLaw from "vue-cookie-law";

export default {
  components: { CookieLaw, Badge, MdLink },
  props: {
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      link_footer: {
        twitter: TWITTER_LINK,
        youtube: YOUTUBE_LINK,
        facebook: FACEBOOK_LINK,
        blog: WP_LINKS.blog,
        help: WP_LINKS.help,
      },
      routing: {
        about: ROUTER_LINKS.about.name,
        contact: ROUTER_LINKS.contact.name,
        vendorContact: ROUTER_LINKS.vendorContact.name,
        terms: ROUTER_LINKS.terms.name,
        announcements: ROUTER_LINKS.announcements.name,
        feedback: ROUTER_LINKS.feedback.name,
        cookies: ROUTER_LINKS.cookies.name,
        developers: ROUTER_LINKS.developers.name,
      },
      hasNew: false,
    };
  },
  methods: {
    checkNewAnnouncements(token) {
      ApiAnnouncements.checkNewAnnouncements(token)
        .then((response) => {
          this.hasNew = response.data == 0 ? false : true;
        })
        .catch((e) => {});
    },

    cronUp() {
      if (sessionStorage.getItem("token")) {
        let token = sessionStorage.getItem("token");
        this.checkNewAnnouncements(token);
      }
    },
  },

  mounted() {
    this.cronUp();
    this.$crontab.addJob({
      name: "checkNewAnnouncements",
      interval: {
        seconds: "/60",
      },
      auto_start: true,
      job: this.cronUp,
    });
  },
};
</script>
<style lang="scss" scoped>
.margin-footer-social-buttons {
  margin-left: -10px;
}

.Cookie--mytheme {
  background-color: white;
  width: 84%;
  margin-left: 8%;
  bottom: 2rem;
  align-items: center;
  justify-content: center;
  min-height: 6rem;
  box-shadow: 0px 12px 28px rgb(0 0 0 / 16%);
  border-radius: 12px;

  .Cookie--mytheme--content-wrapper {
    padding: 1.875rem 4rem 1.875rem 5rem;
    display: block;
    .Cookie--mytheme--content {
      color: #000000;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }

  .Cookie--mytheme--button-wrapper {
    padding-right: 1.25rem;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    align-self: center;

    .Cookie--mytheme--button {
      font-size: 14px;
      width: fit-content;
      line-height: 24px;
      font-weight: 600;
      color: #215a72;
      background: #ffffff;
      border: 1px solid #215a72;
      border-radius: 24px;
      min-width: 6rem;
      min-height: 2rem;
      cursor: pointer;
      padding: 0px 1.5rem;
    }
    .Cookie--mytheme--button-decline {
      margin-top: 0.5rem;
    }
  }
}
@media screen and (max-width: 449px) {
  .Cookie--mytheme {
    width: 90%;
    margin-left: 5%;
    .Cookie--mytheme--content-wrapper {
      padding: 0.47rem 1rem 0.47rem 1.25rem;
    }
    .Cookie--mytheme--button-wrapper {
      padding-right: 1.25rem;
      .Cookie--mytheme--button {
        min-width: 3rem;
        min-height: 1rem;
      }
    }
  }
}
@media screen and (min-width: 450px) and (max-width: 529px) {
  .Cookie--mytheme {
    width: 90%;
    margin-left: 5%;
    .Cookie--mytheme--content-wrapper {
      padding: 0.625rem 1.33rem 0.625rem 1.66rem;
    }
    .Cookie--mytheme--button-wrapper {
      padding-right: 1.66rem;
      .Cookie--mytheme--button {
        min-width: 4.5rem;
        min-height: 1.5rem;
      }
    }
  }
}
@media screen and (min-width: 530px) and (max-width: 767px) {
  .Cookie--mytheme {
    .Cookie--mytheme--content-wrapper {
      padding: 0.93rem 2rem 0.93rem 2.5rem;
    }
    .Cookie--mytheme--button-wrapper {
      padding-right: 2.5rem;
    }
  }
}

@media screen and (min-width: 1920px) {
  //For Projectors or Higher Resolution Screens (Full HD)
  .margin-footer-social-buttons {
    margin-left: -19px;
  }
}

@media screen and (min-width: 3840px) {
  //For 4K Displays (Ultra HD)
  .margin-footer-social-buttons {
    margin-left: -38px;
  }
}

@media screen and (min-width: 7680px) {
  //For 8K Displays (Ultra HD)
  .margin-footer-social-buttons {
    margin-left: -54px;
  }
}
</style>

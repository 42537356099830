import { Model } from "vue-mc";
import Privilege from "../plans/Privilege";
import PrivilegesGroup from "../plans/PrivilegesGroup";
import SubscriptionPeriod from "@/enums/SubscriptionPeriod";
import Address from "@/api/models/user/Address";

class User extends Model {
  options() {
    return {
      editable: true,
      mutateOnChange: true,
    };
  }

  defaults() {
    return {
      userId: 0, //117,
      unreadNotificationsCount: 0,
      displayName: null, //"Nikita Yeryomin",
      password: null,
      addressModel: null,
      zipCode: null,
      selCity: null,
      selState: null,
      email: null, //"nikita@qencode.com",
      streetAddress: null,
      phoneNumber: null,
      subscriptionName: null, //"Silver",
      subscriptionDisplayName: null, //"Silver",
      privilageCode: null,
      privilageTag: null,
      roles: null,
      subscriptionAutoRenewDate: null, //1644666127000, //дата следующего платежа
      subscriptionIsTrial: false,
      subscriptionPeriod: SubscriptionPeriod.annually,
      subscriptionTrialExpDate: null, //дата истечения триала
      subscriptionCancellationDate: null, //дата  отмены подписки
      subscriptionType: null, //"monthly",
      subscriptionPrice: 0, //74,
      subscribeDate: null, //"2022-01-20",//дата когда подписались
      promoCode: null, //"NoteSchool",
      priceWithDiscount: null,
      subscriptionCardLast4: null,
      subscriptionPaypalEmail: null,
      subscriptionCardExpDate: null,
      subscriptionCardType: null,
      periodsWithDiscount: null,
      joinWithTrial: false,
      signedAgreement: null,
      subscriptionPrivileges: null,
      alternateEmail: null,
      paymentMethod: null, //"PayPal",
      subscriptionPrivilegesGroups: null,
      address: null,
      userTrialSubscriptionHasExpired: false,
      userRegularSubscriptionHasExpired: false,
      subscriptionStatus: null, // active //cancelled //expired
      userLogo: null,
      investorType: null,
      userGroup: false,
    };
  }

  // Attribute mutations.
  mutations() {
    return {
      unreadNotificationsCount: Number,
      errorMessage: String,
      userId: Number, //117,
      displayName: String, //"Nikita Yeryomin",
      password: String,
      email: String, //"nikita@qencode.com",
      subscriptionName: String, //"Silver",
      subscriptionDisplayName: String, //"Silver",

      subscriptionIsTrial: Boolean,
      subscriptionTrialExpDate: String,

      subscriptionType: String, //"monthly",
      subscriptionPrice: Number, //74,
      subscribeDate: String, //"2022-01-20",
      promoCode: String, //"NoteSchool",
      priceWithDiscount: Number,
      periodsWithDiscount: Number,
      joinWithTrial: Boolean,
      subscriptionCardLast4: String,
      subscriptionPaypalEmail: String,
      subscriptionCardExpDate: String,
      subscriptionCardType: String,
      paymentMethod: String,
      hasDefaultSubscriptionNow: Boolean,
      hasAbsoluteSubscriptionNow: Boolean,
      userTrialSubscriptionHasExpired: Boolean,
      userRegularSubscriptionHasExpired: Boolean,
      userLogo: String,
      userGroup: Boolean,
    };
  }
}

export default User;

<template>
    <div>
        <!-- slot for parent component to activate the file changer -->
        <div @click="launchFilePicker()">
            <slot name="activator"></slot>
        </div>
        <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
        <input type="file" :accept="acceptedFormats" ref="file" :name="uploadFieldName" @change="onFileChange(
            $event.target.name, $event.target.files)" style="display:none">
        <!-- error dialog displays any potential error messages -->

        <modal-error :showModal="errorDialog" :message="errorText" @close="errorDialog = false" />

    </div>
</template>
<script>
import ModalError from "@/views/modal/ModalError.vue";
export default {
    name: "ImageInput",
    components: {
        ModalError,
    },
    
    data: () => ({
        acceptedFormats: ".png, .jpg, .jpeg",
        errorDialog: null,
        errorText: '',
        uploadFieldName: 'file',
        maxSize: 1024
    }),
    props: {
        // Use "value" to enable using v-model
        value: Object,
    },
    methods: {
        launchFilePicker() {
            this.$refs.file.click();
        },
        onFileChange(fieldName, file) {
            const { maxSize } = this
            let imageFile = file[0]
            if (file.length > 0) {
                let size = imageFile.size / maxSize / maxSize
                if (!imageFile.type.match('/png')&&!imageFile.type.match('/jpg')&&!imageFile.type.match('/jpeg')) {
                    // check whether the upload is an image
                    this.errorDialog = true
                    this.errorText = 'Please choose an image file .png, .jpg, .jpeg'
                } else if (size > 1) {
                    // check whether the size is greater than the size limit
                    this.errorDialog = true
                    this.errorText = 'Your file is too big! Please select an image under 1MB'
                } else {
                    // Append file into FormData and turn file into image URL
                    let formData = new FormData()
                    let imageURL = URL.createObjectURL(imageFile)
                    formData.append(fieldName, imageFile)
                    // Emit the FormData and image URL to the parent component
                    this.$emit('input', { formData, imageURL })
                }
            }
        }
    }
}
</script>
<style></style>
<template>
  <div v-click-outside="hide" class="month-picker-input-container">
    <input
      v-model="selectedDate"
      class="md-input month-picker-input"
      type="text"
      @click="showMonthPicker()"
      :placeholder="placeholder"
      readonly
    />
    <month-picker
      v-show="monthPickerVisible"
      :default-year="defaultYear"
      :default-month="defaultMonth"
      :lang="lang"
      :months="months"
      :no-default="noDefault"
      :show-year="showYear"
      :clearable="clearable"
      :variant="variant"
      :editable-year="editableYear"
      :max-date="maxDate"
      :min-date="minDate"
      :year-only="yearOnly"
      :range="range"
      @input="populateInput"
      @change="$emit('change', $event)"
    />
  </div>
</template>

<script>
import MonthPicker from "./MonthPicker.vue";
import monthPicker from "@/plugins/monthPicker/month-picker";

export default {
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.event);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.event);
      },
    },
  },
  mixins: [monthPicker],
  props: {
    placeholder: {
      type: String,
      default: null,
    },
  },
  name: "MonthPickerInput",
  components: {
    MonthPicker,
  },
  components: {
    MonthPicker,
  },
  mixins: [monthPicker],
  emits: ["change", "input"],
  data() {
    return {
      monthPickerVisible: false,
      selectedDate: null,
    };
  },
  mounted() {
    if (
      this.inputPreFilled &&
      this.defaultMonth !== null &&
      this.defaultYear !== null &&
      !this.range
    ) {
      this.selectedDate = `${this.monthsByLang[this.defaultMonth - 1]}, ${
        this.defaultYear
      }`;
    }
  },
  methods: {
    populateInput(date) {
      if (this.range) {
        this.selectedDate = `${date.rangeFromMonth} - ${date.rangeToMonth}, ${date.year}`;
      } else {
        this.selectedDate = `${date.month}, ${date.year}`;
      }

      this.monthPickerVisible = false;
      this.$emit("input", date);
    },
    showMonthPicker() {
      this.monthPickerVisible = !this.monthPickerVisible;
    },
    hide() {
      this.monthPickerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.month-picker-input-container {
  position: relative;
  padding: 0 !important;
  -webkit-text-fill-color: #3c4858 !important;
  appearance: none;
  z-index: 1000;
}

.month-picker-input {
  font-size: 18px;
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.month-picker__container {
  position: absolute;
  top: 3.5em;
}
</style>

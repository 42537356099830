<template>
  <a
    v-if="isExternal"
    :href="href"
    :target="target"
    :rel="rel"
    :class="mdClass"
    >{{ text }}</a
  >
  <router-link v-else :to="{ name: to }" v-slot="{ href, isActive }" custom>
    <span>
      <a
        v-if="isLink"
        :class="[mdClass, isActive && 'router-link-active']"
        :href="href"
      >
        {{ text }}
      </a>
      <md-button v-if="isButton" :class="mdClass" :href="href">{{
        text
      }}</md-button>
      <md-button v-if="isIconButton" :class="mdClass" :href="href">
        <md-icon>{{ icon }}</md-icon>
      </md-button>
    </span>
  </router-link>
</template>

<script>
import PropValidator from "@/plugins/PropValidator";
export default {
  name: "MdLink",
  props: {
    to: {
      type: String,
    },
    href: {
      type: String,
    },
    text: {
      type: String,
      default: "",
    },
    target: {
      type: String,
      default: "_self",
      ...PropValidator("target", ["_self", "_blank"]),
    },
    rel: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    mdClass: {
      type: [String, Object],
      default: null,
    },
    mdType: {
      type: String,
      default: "link",
      ...PropValidator("md-type", ["link", "button", "button-icon"]),
    },
  },
  computed: {
    isExternal() {
      let res = typeof this.href == "string" && this.href.startsWith("http");
      return res;
    },
    isLink() {
      return this.mdType == "link";
    },
    isButton() {
      return this.mdType == "button";
    },
    isIconButton() {
      return this.mdType === "button-icon";
    },
  },
};
</script>

<style lang="scss"></style>

import axios from "axios";
import {
  LOGIN,
  REFRESH_TOKEN_PATH,
} from "@/api/Constants";


let failedQueue = [];
let isRefreshing = false;

const processQueue = (error) => {
  
  failedQueue.forEach((prom) => {
    if (error) {
      
      prom.reject(error);
    } else {
      
      prom.resolve();
    }
  });

  failedQueue = [];
};


const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "/",
  headers: {
    "Content-type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

instance.interceptors.request.use(
  (config) => {
    if(config.url && config.url.indexOf("blog.noteunlimited.com")>=0) {
      config.headers = {}
      return config;
    }
    let token = sessionStorage.getItem('token');
    if (token) {
      config.headers["X-Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    
    const originalRequest = error.config;
    originalRequest.headers = JSON.parse(
      JSON.stringify(originalRequest.headers || {})
    );

    const refreshToken = sessionStorage.getItem("refreshToken");

    
    const handleError = (error) => {
      
      processQueue(error);
      
      window.dispatchEvent(
        new CustomEvent("check-logout", {
          detail: {
            storage: error,
          },
          from: "Common",
        })
      );
      return Promise.reject(error);
    };

    if (
      refreshToken &&
      error.response?.status === 401 && 
      originalRequest?.url !== REFRESH_TOKEN_PATH &&
      originalRequest?._retry !== true
    ) {
      
      if (isRefreshing) {
        
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return client(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      
      isRefreshing = true;
      originalRequest._retry = true;
      let headers = {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "X-Authorization" :  `Bearer ${refreshToken}`
      }
      

      return axios.get(process.env.VUE_APP_API_URL + REFRESH_TOKEN_PATH, {headers:headers})
        .then((res) => {
          
          const token = res.data?.token;
          if (token) {
            
            sessionStorage.setItem("token",token);
            processQueue(null);
            return instance(originalRequest);
          }else {
            
            return handleError(error);
          }
        }, handleError)
        .finally(() => {
          
          isRefreshing = false;
        });
    }

    
    
    if (
      error.response?.status === 401
    ) {
      
      return handleError(error);
    }
    

    return Promise.reject(error);
  }
);

export default instance;

import Vue from "vue";

class ForSaleHelper extends Vue {
  sortBy() {
    return [
      {
        title: "ITB",
        sortName: "n.itb",
        sortBy: null,
      },
      {
        title: "ITV",
        sortName: "n.currentITV",
        sortBy: null,
      },
      {
        title: "LTV",
        sortName: "n.currentLTV",
        sortBy: null,
      },
      {
        title: "YIELD",
        sortName: "n.yield",
        sortBy: null,
      },
      {
        title: "RATE",
        sortName: "n.interestRateInitial",
        sortBy: null,
      },
      {
        title: "PRICE",
        sortName: "n.notePrice",
        sortBy: null,
      },
      {
        title: "MARKET VALUE",
        sortName: "n.marketValue",
        sortBy: null,
      },
      {
        title: "NOTE POSITION",
        sortName: "n.notePosition",
        sortBy: null,
      },
      {
        title: "NOTE TYPE",
        sortName: "n.noteTypeName",
        sortBy: null,
      },
      {
        title: "UNPAID BALANCE",
        sortName: "n.unpaidBalance",
        sortBy: null,
      },
      {
        title: "COMPANY",
        sortName: "n.companyOwnership",
        sortBy: null,
      },
    ];
  }

  noteTypes() {
    return [
      {
        name: "Performing",
        selected: null,
        value: `' Performing'`,
      },
      {
        name: "Non-Performing",
        selected: null,
        value: `'Non-Performing'`,
      },
      {
        name: "REO",
        selected: null,
        value: `'REO'`,
      },
      {
        name: "Sub-performing",
        selected: null,
        value: `'Sub-performing'`,
      },
      {
        name: "Unknown",
        selected: null,
        value: `'Unknown'`,
      },
    ];
  }

  parseNoteTypes(items) {
    return items.map((item) => {
      return {
        noteTypeId: item.noteTypeId,
        noteType: item.noteType,
        name: item.description,
        selected: null,
        value: `'${item.description}'`,
      };
    });
  }
  notePositions() {
    return [
      {
        name: "1",
        selected: null,
        value: "1",
      },
      {
        name: "2",
        selected: null,
        value: "2",
      },
      {
        name: "3",
        selected: null,
        value: "3",
      },
      {
        name: "4+",
        selected: null,
        value: "4",
      },
    ];
  }
  nFormatter(num, digits = 2) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find((item) => {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }
  stringToFormattedNumber(value) {
    var num = 0.0;
    try {
      num = Number(value.replace(/[,]/g, ""));
    } catch (ex) {
      console.error(ex);
      return "0";
    }
    return this.nFormatter(num);
  }
  setDataForRange(value, name, searchArr) {
    if (
      value &&
      typeof value !== "undefined" &&
      value.model &&
      typeof value.model !== "undefined" &&
      value.model.from != null &&
      value.model.from != "" &&
      value.model.to != null &&
      value.model.to != ""
    ) {
      let modelFor = Number(value.model.from.replace(/[,]/g, ""));
      let modelTo = Number(value.model.to.replace(/[,]/g, ""));
      searchArr.push(`${name}>=${modelFor}`);
      searchArr.push(`${name}<=${modelTo}`);
    } else if (
      value &&
      typeof value !== "undefined" &&
      value.model &&
      typeof value.model !== "undefined" &&
      value.model.from != null &&
      value.model.from != ""
    ) {
      let modelForOnly = Number(value.model.from.replace(/[,]/g, ""));
      searchArr.push(`${name}>=${modelForOnly}`);
    } else if (
      value &&
      typeof value !== "undefined" &&
      value.model &&
      typeof value.model !== "undefined" &&
      value.model.to != null &&
      value.model.to != ""
    ) {
      let modelToOnly = Number(value.model.to.replace(/[,]/g, ""));
      searchArr.push(`${name}<=${modelToOnly}`);
    }
    return searchArr;
  }
  prepareFiltersString(
    filterSearchData,
    priceModelInjection,
    isSmallTabletPortrait
  ) {
    sessionStorage.setItem("filterBy", JSON.stringify(filterSearchData));
    var searchArr = [];

    Object.entries(filterSearchData).map(([key, value]) => {
      if (value.model != null && value.model != "" && value.model != []) {
        switch (value.type) {
          case "input-range": {
            let name = filterSearchData[key]["name"];
            searchArr = this.setDataForRange(value, name, searchArr);
            break;
          }
          case "checkbox": {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined" &&
              value.model.length > 0
            ) {
              if (value.model.includes("4")) {
                searchArr.push(
                  `${filterSearchData[key]["name"]} IN (${value.model.join(
                    ", "
                  )}, ${[...Array(6).keys()].map((i) => i + 5).join(", ")})`
                );
              } else {
                searchArr.push(
                  `${filterSearchData[key]["name"]} IN (${value.model.join(
                    ", "
                  )})`
                );
              }
            } else {
              value.model = [];
            }
            break;
          }
          case "input": {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined"
            ) {
              searchArr.push(
                `${filterSearchData[key]["name"]} LIKE '%${value.model}%'`
              );
            }
            break;
          }
          default: {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined"
            ) {
              searchArr.push(
                `${filterSearchData[key]["name"]} LIKE '%${value.model}%'`
              );
            }
            break;
          }
        }
      }
    });
    searchArr = this.setDataForRange(
      priceModelInjection,
      "n.notePrice",
      searchArr
    );
    return searchArr.join(" and ");
  }
  filtersCountForMedia(filterSearchData, isNoteType, isNotePosition) {
    var count = 0;
    Object.entries(filterSearchData).map(([key, value]) => {
      if (value.model != null && value.model != "" && value.model != []) {
        if (
          (isNoteType && value.name === "n.noteTypeName") ||
          (isNotePosition && value.name === "n.notePosition")
        ) {
          return;
        }
        switch (value.type) {
          case "input-range": {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined" &&
              ((value.model.from != null && value.model.from != "") ||
                (value.model.to != null && value.model.to != ""))
            ) {
              count++;
            }
            break;
          }
          case "checkbox": {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined" &&
              value.model.length > 0
            ) {
              count++;
            }
            break;
          }
          case "input": {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined"
            ) {
              count++;
            }
            break;
          }
          default: {
            if (
              value &&
              typeof value !== "undefined" &&
              value.model &&
              typeof value.model !== "undefined"
            ) {
              count++;
            }
            break;
          }
        }
      }
    });
    return count;
  }
  notEmpty(value) {
    return value != null && value != "";
  }
  capitalize(word) {
    return word
      .toLowerCase()
      .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
  }
}

export default new ForSaleHelper();

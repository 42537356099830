<template>
  <div class="modal modal-bg" :class="{ show: showModal }">
    <modal
      v-if="showModal"
      @close="$emit('close')"
      modal-with-card
      card-classes="md-card-login"
      type="md-layout-item md-xsmall-size-100 md-small-size-66 md-medium-size-50 md-large-size-40 md-xlarge-size-33 mx-auto p-0"
    >
      <template #header>
        <md-card-header class="md-card-header-cyan m-0">
          <h4 class="card-title mt-0">
            {{ header }}
          </h4>
        </md-card-header>
      </template>
      <template slot="body">
        <p id="modalErrorMessage" class="mt-15 text-center" v-html="message"></p>
      </template>

      <template slot="footer">
        <md-button
          class="md-cyan-900 button-border-cyan-900 mx-auto"
          @click="$emit('close')"
        >
          OK
        </md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "ModalError",
  components: { Modal },
  props: {
    showModal: Boolean,
    message: String,
    header: {
      type: String,
      default: "Warning",
    },
  },
};
</script>

import { render, staticRenderFns } from "./NuChart.vue?vue&type=template&id=25212cdf&scoped=true"
import script from "./NuChart.vue?vue&type=script&lang=js"
export * from "./NuChart.vue?vue&type=script&lang=js"
import style0 from "./NuChart.vue?vue&type=style&index=0&id=25212cdf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25212cdf",
  null
  
)

export default component.exports
<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)" v-if="isHeaderHidden">
      <slot name="title" />
      <div class="social-line">
        <slot name="buttons" />
      </div>
    </md-card-header>
    <form>
      <md-card-content class="pb-0">
        <slot name="description" />
        <slot name="error" />
        <slot name="inputs" />
        <slot name="checkbox" />
      </md-card-content>
      <md-card-actions>
        <slot name="footer" />
      </md-card-actions>
    </form>
    <div class="md-card-actions justify-content-between">
      <slot name="subfooter" />
      <slot name="subfooter2" />
    </div>
  </md-card>
</template>

<script>
export default {
  name: "LoginCard",
  props: {
    headerColor: {
      type: String,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function () {
      this.cardHidden = false;
    },
    isHeaderHidden() {
      return this.hideHeader === true ? true : false;
    },
    getClass: function (headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="scss">
.modal {
  color: #3c4858;
}
.justify-content-between {
  justify-content: space-between !important;
}
</style>

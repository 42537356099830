<template>
  <div class="redeemingReport">
    <div class="redeeming-header-pdf" v-if="redeemingFormHeader">
      {{ redeemingFormHeader }}
    </div>
    <div class="redeeming-note-address-pdf">
      {{ addressNote }}
    </div>
    <div class="over">
      <div class="redeeming-images col-xs-12">
        <div class="over">
          <div class="redeeming-selected-image pad-0 col-xs-12">
            <img v-bind:src="noteImage" />
          </div>
        </div>
      </div>

      <div class="redeeming-factors ide-on-export md-layout m-t-15">
        <div class="md-layout-item">
          <div
            class="redeeming-factors-subheader-pdf"
            :class="
              redeemingFactorList.length == 0
                ? 'redeeming-factors-subheader-pdf-empty'
                : ''
            "
            v-if="redeemeengName"
          >
            {{ redeemeengName }}
          </div>
          <ul class="redeeming-factors-list">
            <li
              class="redeeming-factors-item-pdf rf-item"
              v-for="redeemingFactor in redeemingFactorList"
              :key="redeemingFactor"
            >
              {{ redeemingFactor }}
            </li>
          </ul>
          <div
            v-if="redeemingFactorList.length == 0 && redeemingEmpty"
            class="redeeming-empty-pdf"
          >
            {{ redeemingEmpty }}
          </div>
        </div>

        <div class="md-layout-item">
          <div
            class="redeeming-factors-subheader-pdf"
            :class="
              nonRedeemingFactorList.length == 0
                ? 'redeeming-factors-subheader-pdf-empty'
                : ''
            "
            ng-if="redeemingModel.usedParams.unredeemingName"
          >
            {{ unredeemingName }}
          </div>
          <ul class="redeeming-factors-list">
            <li
              class="redeeming-factors-item-pdf rf-item"
              v-for="unRedeemingFactor in nonRedeemingFactorList"
              :key="unRedeemingFactor"
            >
              {{ unRedeemingFactor }}
            </li>
          </ul>
          <div
            v-if="nonRedeemingFactorList.length == 0 && unredeemingEmpty"
            class="redeeming-empty-pdf"
          >
            {{ unredeemingEmpty }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <report-card :shadow-normal="false" :no-colored-shadow="false" :card-image="noteImage">
    <template slot="cardTitleHeader">
      <h3 class="text-shadow">{{ addressNote }}</h3>
    </template>
    <template slot="cardContent">
      <div class="md-layout">
        <div class="md-layout-item md-size-50 md-small-size-100 mr-auto">
          <h6 class="card-category text-info rf-item">Redeeming</h6>
          <ul class="redeeming-factors-list">
            <li class="redeeming-factors-item-pdf " v-for="redeemingFactor in redeemingFactorList"
              :key="redeemingFactor">
              {{ redeemingFactor }}
            </li>
          </ul>
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 mr-auto">
          <h6 class="card-category text-info rf-item">Not So</h6>
          <ul class="redeeming-factors-list">
            <li class="redeeming-factors-item-pdf " v-for="unRedeemingFactor in nonRedeemingFactorList"
              :key="unRedeemingFactor">
              {{ unRedeemingFactor }}
            </li>
          </ul>
        </div>
      </div>

    </template>
  </report-card> -->
</template>

<script>
// import html2pdf from 'html2pdf.js'
export default {
  name: "RedeemingReportCard",
  props: {
    redeemingFormHeader: {
      type: String,
    },
    addressNote: {
      type: String,
    },
    noteImage: {
      type: String,
    },
    nonRedeemingFactorList: {
      type: Array,
    },
    redeemingFactorList: {
      type: Array,
    },
    redeemeengName: {
      type: String,
    },
    unredeemingName: {
      type: String,
    },
    redeemingFactor: {
      type: String,
    },
    unredeemingEmpty: {
      type: String,
    },
  },
  data() {
    let horizontal = false;
    let nameView = "Portrait";
    return {
      nameView,
      horizontal,
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    changeView(value) {
      if (value == "on") {
        this.horizontal = true;
        this.nameView = "Landscape";
      } else {
        this.nameView = "Portrait";
        this.horizontal = false;
      }
    },
    async saveReport() {
      // var id = 'reportPdf';
      // var orientation = 'p';
      // var element = document.getElementById(id);
      // var opt = {
      //   margin: 0,
      //   filename: 'RedeemingReport.pdf',
      //   image: { type: 'jpeg', quality: 1 },
      //   html2canvas: { scale: 1 },
      //   jsPDF: {
      //     orientation: orientation,
      //     format: 'a4',
      //     putOnlyUsedFonts: true,
      //   }
      // };
      // html2pdf().set(opt).from(element).save();
    },
    cancelRedeemingReportPreview() {},
    showCard: function () {
      this.cardHidden = false;
    },
    isHeaderHidden() {
      return this.hideHeader === true ? true : false;
    },
  },
};
</script>
<style lang="scss">
.over {
  overflow: hidden;
}

.redeeming-header-pdf,
.redeeming-note-address-pdf {
  font-size: 18px;
  font-weight: 700;
  padding: 5px;
}

.redeeming-selected-image {
  background: #fafafa;
  text-align: center;
}

.redeeming-factors-subheader-pdf {
  font-size: 16px;
  font-weight: bold;
  padding-left: 40px;
  text-align: left;
}

.redeeming-factors-subheader-pdf-empty {
  text-align: left;
  padding-left: 0;
}

.redeeming-factors-list {
  list-style-type: none;
}

.redeeming-factors-item-pdf {
  padding: 5px 0;
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
}

.redeeming-empty-pdf {
  text-align: left;
}
</style>

<template>
    <component :is="baseComponent" :to="link.path ? link.path : '/'" class="md-list-item" :class="{ active: isActive }" tag="li" :v-slot="link.path">
        <a v-if="isMenu" href="#" class="nav-link inactive-link" :aria-expanded="!collapsed" data-toggle="collapse">
            <div class="md-list-item-content d-block md-ripple">
                <md-icon v-if="link.icon">{{ link.icon }}</md-icon>
                <md-icon v-if="link.image">
                    <md-avatar style="height: auto; width: auto; min-width: auto">
                        <img :src="link.image" alt="Avatar"/>
                    </md-avatar>
                </md-icon>
                <p :class="{ 'fw-500': bold }">
                    {{ link.name }}
                    <b class="caret"></b>
                </p>
            </div>
        </a>

        <collapse-transition>
            <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
                <ul class="nav mt-0">
                    <slot></slot>
                </ul>
            </div>
        </collapse-transition>

        <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
            <component :to="link.path" @click.native="linkClick" :is="elementType(link, false)" :class="{ active: link.active }" class="nav-link mt-0"
                :target="link.target" :v-slot="link.path">
                <template v-if="addLink">
                    <div class="md-list-item-content d-block md-ripple">
                        <span class="sidebar-normal">{{ link.name }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="md-list-item-content md-ripple">
                        <md-icon>{{ link.icon }}</md-icon>
                        <p>{{ link.name }}</p>
                    </div>
                </template>
            </component>
        </slot>
    </component>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import {Badge} from "@/components";

export default {
    name: "sidebar-item",
    components: {
        CollapseTransition,
        Badge,
    },
    props: {
        menu: {
            type: Boolean,
            default: false,
        },
        opened: {
            type: Boolean,
            default: false,
        },
        link: {
            type: [String, Object],
            default: () => {
                return {
                    name: "",
                    path: "",
                    icon: "",
                    image: "",
                    badge: 0,
                    children: [],
                };
            },
        },
        bold: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: "router-link",
        },
    },
    provide() {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild,
        };
    },
    inject: {
        addLink: {default: null},
        removeLink: {default: null},
        autoClose: {
            default: true,
        },
    },
    data() {
        return {
            children: [],
            collapsed: !this.opened,
        };
    },
    computed: {
        baseComponent() {
            return this.isMenu || this.link.isRoute ? "li" : "router-link";
        },
        linkPrefix() {
            if (this.link.name) {
                let words = this.link.name.split(" ");
                return words.map((word) => word.substring(0, 1)).join("");
            }
            return false;
        },
        isMenu() {
            return this.children.length > 0 || this.menu === true;
        },
        isActive() {
            if (this.$route && this.$route.path) {
                let matchingRoute = this.children.find((c) =>
                    this.$route.path.startsWith(c.link.path)
                );
                if (matchingRoute !== undefined) {
                    return true;
                }
            }
            return false;
        },
    },
    methods: {
        addChild(item) {
            const index = this.$slots.default.indexOf(item.$vnode);
            this.children.splice(index, 0, item);
        },
        removeChild(item) {
            const tabs = this.children;
            const index = tabs.indexOf(item);
            tabs.splice(index, 1);
        },
        elementType(link, isParent = true) {
            if (link.isRoute === false) {
                return isParent ? "li" : "a";
            } else {
                return "router-link";
            }
        },
        linkAbbreviation(name) {
            const matches = name.match(/\b(\w)/g);
            return matches.join("");
        },
        linkClick() {
            if (!this.addLink) {
                this.$sidebar.collapseAllMenus();
            }

            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
        },
        collapseMenu() {
            if (this.collapsed) {
                this.$sidebar.addSidebarLink(this);
                this.$sidebar.collapseAllMenus();
            }

            this.collapsed = !this.collapsed;
        },
        collapseSubMenu(link) {
            link.collapsed = !link.collapsed;
        },
        hideSidebar() {
            this.$emit("changed");
            if (
                this.autoClose &&
                this.$sidebar &&
                this.$sidebar.showSidebar === true
            ) {
                this.$sidebar.displaySidebar(false);
            }
        },
    },
};
</script>
<style></style>

<template>
  <div
    class="sidebar-filter sidebar-filter-right"
    :class="{ 'sidebar-filter-open-right': isActive }"
  >
    <div class="title">
      <p class="for-sale-title for-sale-flex-center cyan-900">Sort Settings</p>
      <md-button
        class="md-simple md-close md-just-icon m-0"
        @click="closeSortList"
      >
        <md-icon class="opacity-100">clear</md-icon>
      </md-button>
    </div>
    <div class="buttons-container">
      <md-button class="md-cyan-900 m-0" @click="onClearSortingClick">
        Clear Sorting
      </md-button>
    </div>
    <div class="overflow-y-auto">
      <md-list>
        <md-list-item
          class="border-top-light-gray"
          v-for="item in sortData"
          :key="item.title"
        >
          <span class="md-list-item-text">{{ item.title }}</span>
          <div class="md-group md-group-two-buttons">
            <md-button
              class="md-just-icon button-border-cyan-900-2px"
              :class="{
                'md-cyan-900': item.sortBy == 'asc',
                'md-white': item.sortBy != 'asc',
              }"
              @click="
                save(item, 'asc');
                closeSortList();
                $emit('clear-pagination');
                $emit('sort-by', 1);
              "
            >
              <i class="fa fa-sort-amount-up-alt"></i>
            </md-button>
            <md-button
              class="md-just-icon md-white button-border-cyan-900-2px"
              :class="{
                'md-cyan-900': item.sortBy == 'desc',
                'md-white': item.sortBy != 'desc',
              }"
              @click="
                save(item, 'desc');
                closeSortList();
                $emit('clear-pagination');
                $emit('sort-by', 1);
              "
            >
              <i class="fa fa-sort-amount-down"></i>
            </md-button>
          </div>
        </md-list-item>
      </md-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarSortBy",
  props: {
    isActive: Boolean,
    sortData: Array,
  },

  methods: {
    save(item, value) {
      this.clearSortingClick();
      item.sortBy = value;
      sessionStorage.setItem("orderBy", JSON.stringify(item));
    },
    closeSortList() {
      this.$emit("close-sort-list");
    },
    setSortDefault() {
      this.$emit("set-sort-default");
    },

    clearSortingClick() {
      sessionStorage.removeItem("orderBy");
      const sortData = this.sortData;
      Object.entries(sortData).map(([key, value]) => {
        if (value.sortBy != null) {
          sortData[key]["sortBy"] = null;
        }
      });
    },
    onClearSortingClick() {
      this.clearSortingClick();
      this.closeSortList();
      this.$emit("clear-pagination");
      this.$emit("sort-by", 1);
      this.setSortDefault();
    },
  },
};
</script>

<template>
    <div v-click-outside="hideMenu" id="menu-wrapper">
        <md-toolbar class="md-white z-index-toolbar py-0 md-fixed" id="toolbar" :class="extraNavClasses">
            <div class="md-toolbar-row md-collapse-lateral">
                <div class="md-toolbar-section-start" :class="{ 'user-logo-container': userLogo }">
                    <router-link :to="{ name: link.index }" v-slot="{ href }" custom>
                        <a :href="href" class="md-title-image" :class="logoInactive">
                            <img :src="imagePath" alt="NoteUnlimited" class="img-fluid img-logo" :class="{ 'user-logo': userLogo }" />
                        </a>
                    </router-link>
                </div>
                <div class="md-toolbar-section-end">
                    <md-button class="md-just-icon md-simple md-white md-toolbar-toggle" :class="{ toggled: NavbarStore.showNavbar }" @click="toggleNavbarMobile()">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </md-button>
                </div>
                <div class="md-collapse">
                    <div class="md-collapse-wrapper">
                        <md-list>
                            <md-list-item :disabled="isExpired || AgreementStore.isExpired" class="no-hover" :class="isForSalePageActive" id="for-sale"
                                v-if="hasToken && !isVendor && hasMarketplacePrivilege" @click="checkMarcketPlacePermittion">
                                <p>{{ marketplaceMenuLabel }}</p>
                            </md-list-item>

                            <li id="notes_drop_down" class="md-list-item" :class="{'inactive-link opacity-60': isExpired || AgreementStore.isExpired,}"
                                v-if="hasToken && !isVendor">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down" >
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" :disabled="isExpired || AgreementStore.isExpired"
                                                    data-toggle="dropdown">
                                                    <p>{{ $t("menu-header.notes") }}</p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li v-if="hasToken && !isVendor">
                                                    <a :href="requestDashboardPath" @click="mobileLinkClick">
                                                        {{ $t("menu-header.notes-dashboard") }}
                                                    </a>
                                                </li>
                                                <li v-if="hasToken && !isVendor">
                                                    <a :href="transactionsPath" @click="mobileLinkClick">
                                                        {{ $t("menu-header.transactions") }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>

                            <md-list-item id="properties" :disabled="isExpired || AgreementStore.isExpired" :href="requestPropertiesPath"
                                v-if="hasToken && !isVendor" @click="mobileLinkClick">
                                <p>{{ $t("menu-header.properties") }}</p>
                            </md-list-item>

                            <md-list-item id="calculator" :disabled="AgreementStore.isExpired" :to="routing.calculator" @click="mobileLinkClick">
                                <p>{{ $t("menu-header.calculator") }}</p>
                            </md-list-item>

<!--                            <md-list-item id="orders" :disabled="isExpired || AgreementStore.isExpired" :href="buyerOrdersPath" v-if="hasToken && !isVendor" @click="mobileLinkClick">
                                <p>{{ $t("menu-header.orders") }}</p>
                            </md-list-item>-->

                            <li id="notes_drop_down" class="md-list-item" :class="{'inactive-link opacity-60': isExpired || AgreementStore.isExpired}"
                                v-if="hasToken && !isVendor">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down">
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" :disabled="isExpired || AgreementStore.isExpired"
                                                           data-toggle="dropdown">
                                                    <p>{{ $t("menu-header.purchases") }}</p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li v-if="hasToken && !isVendor">
                                                    <a :href="link.buyerOffers" @click="mobileLinkClick">{{$t("menu-header.offers")}}</a>
                                                </li>
                                                <li v-if="hasToken && !isVendor">
                                                    <a :href="buyerOrdersPath" @click="mobileLinkClick">
                                                        {{ $t("menu-header.orders") }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>

<!--                            <md-list-item id="vendor_portal" :href="sellerOrdersPath" :disabled="isExpired || AgreementStore.isExpired" v-if="(isVendor || isNoteSaller) && hasToken"
                                @click="mobileLinkClick">
                                <p>{{ $t("menu-header.vendor") }}</p>
                            </md-list-item>-->

                            <li id="notes_drop_down" class="md-list-item" :class="{'inactive-link opacity-60': isExpired || AgreementStore.isExpired}"
                                v-if="(isVendor || isNoteSaller) && hasToken">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down">
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" :disabled="isExpired || AgreementStore.isExpired"
                                                           data-toggle="dropdown">
                                                    <p>{{ $t("menu-header.sales") }}</p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li v-if="(isVendor || isNoteSaller) && hasToken">
                                                    <a :href="link.sellerOffers" @click="mobileLinkClick">{{$t("menu-header.offers")}}</a>
                                                </li>
                                                <li v-if="(isVendor || isNoteSaller) && hasToken">
                                                    <a :href="sellerOrdersPath" @click="mobileLinkClick">
                                                        {{ $t("menu-header.orders") }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>

                            <li id="profile_drop_down" class="md-list-item" v-if="hasToken">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down">
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" data-toggle="dropdown">
                                                    <p>{{ $t("menu-header.profile") }}</p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li v-if="hasToken && isAdmin" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <a :href="manageDashboardPath" @click="mobileLinkClick">
                                                        {{ $t("menu-header.admin") }}
                                                    </a>
                                                </li>
                                                <li v-if="!isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.profile" v-slot="{ href }" custom >
                                                        <a :href="href" @click="mobileLinkClick">
                                                            {{ $t("menu-header.settings") }}
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.profile" v-slot="{ href }" custom >
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Personal Info
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.security" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Security
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.companies" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Companies
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.your_plan" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Your plan
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.payments" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Payments
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && hasAccessToDeveloperPage()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.api_keys" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            API keys
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.userGroupSettings" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Group Settings
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && isAdminOfUserGroup()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.userGroupHistory" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Group History
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && isAdminOfUserGroup()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.userGroupMembers" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Group Members
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && isAdminOfUserGroup()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.userGroupInvestorProfiles" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Investor Profiles
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && hasAccessToInvestorProfile()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.investorProfile" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Investor Profile
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li v-if="isMobile && hasAccessToInvestorProfileCustom()" :class="{'inactive-link opacity-60': AgreementStore.isExpired}">
                                                    <router-link :to="routing.investorProfileCustom" v-slot="{ href }" custom>
                                                        <a :href="href" @click="mobileLinkClick">
                                                            Investor Profile
                                                        </a>
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <a @click="logout">{{ $t("menu-header.logout") }}</a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>
                            <li class="md-list-item" v-if="user && user.unreadNotificationsCount > 0">
                                <md-button class="md-cyan-900 md-menu-button md-simple mw-fit-content envelope-height" :disabled="AgreementStore.isExpired"
                                           @click="mobileOpenModalClick">
                                    <p class="hidden-lg navbar-default-link-color pr-10">
                                        {{ $t("menu-header.notifications") }}
                                    </p>
                                    <md-icon class="menu-icon-size md-simple">
                                        {{ $t("icon.email") }}
                                    </md-icon>
                                    <badge type="danger" class="badge-menu d-flex align-self-start ml-0">
                                        {{ user.unreadNotificationsCount }}
                                    </badge>
                                </md-button>
                            </li>
                            <li class="md-list-item" v-if="subscription != 0" :class="{'inactive-link opacity-60': AgreementStore.isExpired,}">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down">
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" data-toggle="dropdown" :disabled="AgreementStore.isExpired">
                                                    <p v-if="subscription == 1">
                                                        {{ $t("menu-header.plans") }}
                                                    </p>
                                                    <p v-if="subscription == 2">
                                                        {{ $t("menu-header.subscribe") }}
                                                    </p>
                                                    <p v-if="subscription == 3">
                                                        {{ $t("menu-header.upgrade") }}
                                                    </p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li>
                                                    <a :href="link.personal" @click="mobileLinkClick">{{$t("menu-header.personal")}}</a>
                                                </li>
                                                <li>
                                                    <a :href="link.business" @click="mobileLinkClick">{{$t("menu-header.business")}}</a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>

                            <md-list-item :disabled="isExpired || AgreementStore.isExpired" v-if="hasToken && !isVendor && isExpired">
                                <p>{{ $t("menu-header.resources") }}</p>
                            </md-list-item>

                            <li class="md-list-item" :class="{'inactive-link opacity-60': AgreementStore.isExpired,}" v-if="hasToken && !isVendor && !isExpired">
                                <a href="javascript:void(0)" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                                    <div class="md-list-item-content">
                                        <drop-down direction="down">
                                            <template #title>
                                                <md-button class="md-button-link md-simple dropdown-toggle lh-normal" data-toggle="dropdown" :disabled="AgreementStore.isExpired">
                                                    <p>{{ $t("menu-header.resources") }}</p>
                                                </md-button>
                                            </template>
                                            <ul class="dropdown-menu dropdown-menu-right">
                                                <li>
                                                    <a :href="link.csv_notes" @click="mobileLinkClick">
                                                        {{$t("menu-header.download_notes_template_csv_file")}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="link.csv_nod" @click="mobileLinkClick">
                                                        {{$t("menu-header.download_nod_template_csv_file")}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="link.csv_note_payment_history" @click="mobileLinkClick">
                                                        {{$t("menu-header.download_note_payment_history_template_csv_file")}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="link.csv_properties" @click="mobileLinkClick">
                                                        {{$t("menu-header.download_properties_template_csv_file")}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </drop-down>
                                    </div>
                                </a>
                            </li>

                            <md-list-item :to="routing.login" v-if="!hasToken" @click="mobileLinkClick">
                                <p>{{ $t("menu-header.sign-in") }}</p>
                            </md-list-item>

                            <md-list-item :to="routing.register" v-if="!hasToken" @click="mobileLinkClick">
                                <md-button class="md-primary md-round">
                                    {{ $t("menu-header.join-for-free") }}
                                </md-button>
                            </md-list-item>
                        </md-list>
                    </div>
                </div>
            </div>
        </md-toolbar>
        <!-- show modal -->
        <modal-notification :open="notificationModal" @close="closeNotificationModal">
        </modal-notification>
        <modal-error :showModal="errorModal" :message="errorMessage" @close="closeErrorModal"/>
    </div>
</template>

<script>
import ApiUser from "@/api/methods/ApiUser";
import {UserDataHelper, ErrorHelper} from "@/api/helpers";
import ApiUserProfile from "@/api/methods/ApiUserProfile";
import Subscription from "@/enums/Subscription";
import ModalNotification from "@/views/modal/ModalNotification.vue";
import {Badge} from "@/components";
import Mixins from "@/plugins/basicMixins";
import User from "@/api/models/user/User";
import {
    ROUTER_LINKS,
    CSV,
    REDIRECT_LINKS,
    PAGES_WITHOUT_SUBSCRIPTION,
} from "@/plugins/Constants";
import {PRIVILEGES, LOGOS, MQ1X, MQ2X, MQ3X, MQ4X} from "@/layout/Constants";
import ModalError from "@/views/modal/ModalError.vue";
import {mapActions} from "vuex";
import {OffersPaths} from "@/router/const/OffersPaths";

let resizeTimeout;

function resizeThrottler(actualResizeHandler) {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            actualResizeHandler();
        }, 66);
    }
}

export default {
    components: {
        ModalError,
        ModalNotification,
        Badge,
    },
    name: "MenuHeader",
    mixins: [
        Mixins.Loading,
        Mixins.DetectMobile,
        Mixins.DisablePageOverflow,
        Mixins.ShowToastr,
        Mixins.SetPath,
    ],
    data() {
        return {
            extraNavClasses: "",
            notificationModal: false,
            hasToken: false,
            isExpired: false,
            userName: null,
            user: null,
            isAdmin: false,
            isVendor: false,
            hasMarketplacePrivilege: false,
            isNoteSaller: false,
            routing: {
                calculator: ROUTER_LINKS.calculator.path,
                login: ROUTER_LINKS.login.path,
                register: ROUTER_LINKS.register.path,
                profile: ROUTER_LINKS.profile.path,
                security: ROUTER_LINKS.security.path,
                companies: ROUTER_LINKS.companies.path,
                your_plan: ROUTER_LINKS.your_plan.path,
                payments: ROUTER_LINKS.payments.path,
                api_keys: ROUTER_LINKS.profile_api.path,
                userGroupSettings: ROUTER_LINKS.userGroupSettings.path,
                userGroupHistory: ROUTER_LINKS.userGroupHistory.path,
                userGroupMembers: ROUTER_LINKS.userGroupMembers.path,
                userGroupInvestorProfiles: ROUTER_LINKS.userGroupInvestorProfiles.path,
                investorProfile: ROUTER_LINKS.investorProfile.path,
                investorProfileCustom: ROUTER_LINKS.investorProfileCustom.path,
                index: ROUTER_LINKS.index.path,
                marketplace: ROUTER_LINKS.forSale.path,
            },
            link: {
                index: ROUTER_LINKS.index.name,
                business: process.env.VUE_APP_BASE_URL + "#" + ROUTER_LINKS.business.path,
                personal: process.env.VUE_APP_BASE_URL + "#" + ROUTER_LINKS.personal.path,
                csv_notes: process.env.VUE_APP_API_URL + CSV.resources + CSV.notes,
                csv_nod: process.env.VUE_APP_API_URL + CSV.resources + CSV.nod,
                csv_note_payment_history: process.env.VUE_APP_API_URL + CSV.resources + CSV.note_payment_history,
                csv_properties: process.env.VUE_APP_API_URL + CSV.resources + CSV.properties,
                userProfile: process.env.VUE_APP_BASE_URL + "#" + ROUTER_LINKS.userProfile.path,
                buyerOffers: process.env.VUE_APP_BASE_URL + "#" + OffersPaths.buyerOffers.path,
                sellerOffers: process.env.VUE_APP_BASE_URL + "#" + OffersPaths.sellerOffers.path,
            },
            subscription: Subscription.nothin,
            manageDashboardPath: null,
            requestDashboardPath: null,
            requestPropertiesPath: null,
            historyPath: null,
            transactionsPath: null,
            profilePath: null,
            sellerOrdersPath: null,
            propertiesPath: null,
            buyerOrdersPath: null,
            logos: LOGOS,
            imagePath: null,
            userLogo: false,
            colorOnScroll: 400,
            errorModal: false,
            errorMessage: null,
        };
    },
    computed: {
        paddingTopStyle() {
            return `${this.padding}`;
        },
        logoInactive() {
            return this.$route.name == "index" || this.AgreementStore.isExpired ? "inactive-link" : "";
        },
        isForSalePageActive() {
            return this.$route.name == "for-sale" ? "router-link-active" : "";
        },
        marketplaceMenuLabel() {
            if (this.user != null && this.user.marketplaceMenuLabel != null && this.user.marketplaceMenuLabel !== "null" && this.user.marketplaceMenuLabel != "") {
                return this.user.marketplaceMenuLabel;
            } else {
                return this.$t("menu-header.for-sale");
            }
        }
    },
    methods: {
        ...mapActions("UserInfo", {
            setStoreToken: "setToken",
            setStoreUserData: "setUserData"
        }),
        hideMenu() {
            if (this.NavbarStore.showNavbar === true) {
                this.toggleNavbarMobile();
            }
        },
        checkMarcketPlacePermittion() {
            //:to="routing.marketplace" fix this problem
            if (this.isExpired) {
                return;
            }
            this.mobileLinkClick();
            var isForSaleAv = false;
            if (this.user) {
                isForSaleAv = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.VIEW_NOTES_FOR_SALE);
            }
            if (isForSaleAv) {
                this.$router.push(ROUTER_LINKS.forSale.path).catch(() => {
                });
            } else {
                this.errorMessage = this.replace(
                    this.$t("messages.for-sale-access-error")
                );
                this.errorModal = true;
            }
        },
        checkPaymensHistoryPermittion() {
            if (this.isExpired) return;
            this.mobileLinkClick();

            if (UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.NOTE_MAKEPAYMENT)) {
                location.href = this.historyPath;
                return;
            }
            let token = sessionStorage.getItem("token");
            this.showLoading();
            ApiUser.getPaymentsCount(token)
                .then((response) => {
                    this.hideLoading();
                    if (response.data && response.data > 0) {
                        location.href = this.historyPath;
                    } else {
                        this.errorMessage = this.replace(
                            this.$t("messages.payment-history-access-error")
                        );
                        this.errorModal = true;
                    }
                })
                .catch((e) => {
                    this.hideLoading();
                });
        },
        replace(text) {
            return text
                .replace("{hrefpersonal}", this.link.personal)
                .replace("{hrefbusiness}", this.link.business);
        },
        getUserData() {
            let token = sessionStorage.getItem("token");
            let _ = this.setPath(token);
            if (!this.hasToken) {
                return;
            }
            ApiUser.getUserActualData(token)
                .then((response) => {
                    this.user = UserDataHelper.updateUserData(response.data, UserDataHelper.getUser());
                    this.setStoreUserData(this.user)
                    this.checkExpiration();
                })
                .catch((e) => {
                    if (ErrorHelper.isSessinExpired(e)) {
                        this.updateRefreshTokenOrLogout();
                    }
                });
        },
        updateRefreshTokenOrLogout() {
            this.afterLogout(this.routing.login, {
                redirect: this.$router.currentRoute.path,
            });
        },
        checkExpiration() {
            sessionStorage.setItem("user", JSON.stringify(this.user));
            if (
                UserDataHelper.isExpired(this.user) &&
                !PAGES_WITHOUT_SUBSCRIPTION.includes(this.$route.path)
            ) {
                this.$router.push(ROUTER_LINKS.trial_expired.path);
            }
        },
        mobileLinkClick() {
            this.NavbarStore.showNavbar = false;
        },
        hasAccessToDeveloperPage() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(
                this.user,
                PRIVILEGES.API_CALL
            );

            return hasAccess;
        },
        logout() {
            //TODO recalculate
            let token = sessionStorage.getItem("token");
            ApiUser.logout(token)
                .then((_) => {
                    this.afterLogout(this.routing.index, null);
                })
                .catch((e) => {
                    let msg = ErrorHelper.message(e, null);
                    if (msg) {
                        this.showError(this.name, msg);
                    }
                });
        },
        afterLogout(path, query) {
            if (window.innerWidth <= 991) {
                this.mobileLinkClick();
            }
            this.clear();
            if (this.$route.path != path) {
                this.$router
                    .replace({
                        path: path,
                        query: query,
                    })
                    .catch((err) => {
                    });
            } else if (query) {
                this.$router.replace({query: query}).catch((err) => {
                });
            }
        },
        clear() {
            sessionStorage.clear();
            this.setStoreToken(null);
            this.setStoreUserData(null);
            this.isAdmin = false;
            this.isVendor = false;
            this.hasMarketplacePrivilege = false;
            this.isNoteSaller = false;
            this.AgreementStore.isExpired = false;
            this.isExpired = false;
            this.hasToken = false;
            this.subscription = Subscription.plans;
            this.user = null;
            this.setDefoults();
        },
        toggleNavbarMobile() {
            this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
        },
        mobileOpenModalClick() {
            this.NavbarStore.showNavbar = false;
            this.disableScroll();
            this.notificationModal = true;
        },
        closeNotificationModal() {
            this.notificationModal = false;
            this.enableScrollMap();
        },
        closeErrorModal() {
            this.errorModal = false;
            this.errorMessage = null;
            this.enableScrollMap();
        },
        handleScroll() {
            let scrollValue =
                document.body.scrollTop || document.documentElement.scrollTop;
            let navbarColor = document.getElementById("toolbar");
            this.currentScrollValue = scrollValue;
            if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
                this.extraNavClasses = `md-${this.type}`;
                navbarColor.classList.remove("md-transparent");
            } else {
                if (this.extraNavClasses) {
                    this.extraNavClasses = "";
                    navbarColor.classList.add("md-transparent");
                }
            }
        },
        scrollListener() {
            resizeThrottler(this.handleScroll);
        },
        scrollToElement() {
            let element_id = document.getElementById("downloadSection");
            if (element_id) {
                element_id.scrollIntoView({block: "end", behavior: "smooth"});
            }
        },
        initRedirect(redirectUrl) {
            this.manageDashboardPath = redirectUrl + REDIRECT_LINKS.manage;
            this.requestDashboardPath = redirectUrl + REDIRECT_LINKS.request + "mode=null";
            this.requestPropertiesPath = redirectUrl + REDIRECT_LINKS.properties;
            this.historyPath = redirectUrl + REDIRECT_LINKS.history;
            this.transactionsPath = redirectUrl + REDIRECT_LINKS.transactions;
            this.profilePath = redirectUrl + REDIRECT_LINKS.profile;
            this.sellerOrdersPath = redirectUrl + REDIRECT_LINKS.vendor;
            this.buyerOrdersPath = redirectUrl + REDIRECT_LINKS.orders;
        },
        addListeners() {
            document.addEventListener("scroll", this.scrollListener, {
                passive: true,
            });
            window.addEventListener("resize", this.onResize);
            window.addEventListener("check-logout", (event) => {
                let error = event.detail.storage;
                this.afterLogout(this.routing.login, {
                    redirect: this.$router.currentRoute.path,
                });
            });
            window.addEventListener("user-data-changed", (event) => {
                this.user = event.detail.storage;
                this.setDefoults();
            });
            window.addEventListener("user-logged-out", (event) => {
                this.clear();
                this.setDefoults();
                let redirectPath = event.detail.storage;
                if (redirectPath) {
                    //TODO set redirect;
                }
            });
        },
        setDefoults() {
            this.checkWebpSupport();
            let token = sessionStorage.getItem("token");
            if (token && !this.user) {
                this.user = UserDataHelper.getUser();
            }
            if (this.user) {
                this.isExpired = this.user.userTrialSubscriptionHasExpired || this.user.userRegularSubscriptionHasExpired;
                this.isVendor = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.VENDOR);
                this.isNoteSaller = UserDataHelper.isPrivilegeExists(this.user,PRIVILEGES.ADD_NOTE_FOR_SALE);
                this.hasMarketplacePrivilege = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.VIEW_NOTES_FOR_SALE);

                this.isAdmin = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.ADMIN_PANEL);
                if (this.isAdmin || this.isVendor) {
                    this.subscription = Subscription.nothin;
                } else {
                    this.subscription = Subscription.upgrade;
                }
                let path = this.setPath(token);
                this.initRedirect(path);
            } else {
                let token = sessionStorage.getItem("token");
                if (token) {
                    this.featchUserDetails(token);
                }
            }
        },
        mediaQueryChange(path, mq) {
            if (this.user != null && this.user.userLogo != null && this.user.userLogo !== "null") {
                this.imagePath = this.user.userLogo;
                this.userLogo = true;
                return true;
            }
            if (matchMedia(mq).matches) {
                this.imagePath = path;
                this.userLogo = false;
                return true;
            }
            return false;
        },
        featchUserDetails(token) {
            ApiUserProfile.getUserDetails(token)
                .then((response) => {
                    this.user = UserDataHelper.createUserData(response.data);
                    this.setStoreUserData(this.user)
                    sessionStorage.setItem("user", JSON.stringify(this.user));
                    this.checkExpiration();
                    window.dispatchEvent(
                        new CustomEvent("user-data-changed", {
                            detail: {
                                storage: this.user,
                            },
                        })
                    );
                })
                .catch((e) => {
                    if (ErrorHelper.isSessinExpired(e)) {
                        this.updateRefreshTokenOrLogout();
                    }
                });
        },
        setToken() {
            if (this.hasToken && this.user) {
                return;
            }
            let token = this.$route.query.jwt;
            if (!token) {
                return;
            }
            sessionStorage.setItem("token", token);
            if (this.user == null) {
                this.user = new User();
            }
            this.hasToken = true;
            this.featchUserDetails(token);
        },
        checkWebpSupport() {
            var htmlTag = document.querySelector("html");
            var listMQ = [MQ1X, MQ2X, MQ3X, MQ4X];
            var index = 0;
            if (htmlTag.classList.contains("no-webp")) {
                var listLogo = [
                    this.logos.logo,
                    this.logos.logo_2k,
                    this.logos.logo_4k,
                    this.logos.logo_8k,
                ];
                while (
                    !this.mediaQueryChange(listLogo[index], listMQ[index]) &&
                    index < listLogo.length &&
                    index < listMQ.length
                    ) {
                    index += 1;
                }
                if (!this.imagePath) {
                    this.imagePath = this.logos.logo;
                }
            } else {
                var listLogo = [
                    this.logos.logo_webp,
                    this.logos.logo_webp_2k,
                    this.logos.logo_webp_4k,
                    this.logos.logo_webp_8k,
                ];
                while (
                    !this.mediaQueryChange(listLogo[index], listMQ[index]) &&
                    index < listLogo.length &&
                    index < listMQ.length
                    ) {
                    index += 1;
                }
                if (!this.imagePath) {
                    this.imagePath = this.logos.logo_webp;
                }
            }
        },
        setToolbarHeight() {
            let toolbar = document.getElementById("toolbar");
            let container = document.getElementById("view-container");
            if (toolbar) {
                var toolbarIntHeight = toolbar.offsetHeight;
                if (container) {
                    container.style.paddingTop = `${toolbarIntHeight}px`;
                }
            }
        },
        onResize() {
            this.checkWebpSupport();
            this.setToolbarHeight();
        },
        hasAccessToInvestorProfile() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.INVESTOR_PROFILE);

            return hasAccess;
        },
        hasAccessToInvestorProfileCustom() {
            if (!this.user) {
                return false;
            }
            let hasAccess = UserDataHelper.isPrivilegeExists(this.user, PRIVILEGES.INVESTOR_PROFILE_CUSTOM);

            return hasAccess;
        },
        isAdminOfUserGroup() {
            if (!this.user) {
                return false;
            }
            let hasAccess = this.user.adminGroups && this.user.adminGroups.length > 0;

            return hasAccess;
        },
    },
    watch: {
        $route(to, from) {
            this.setToolbarHeight();
            this.closeErrorModal();
        },
        hasToken: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$forceUpdate();
                    this.checkWebpSupport();
                }
            },
        },
        "NavbarStore.showNavbar": {
            immediate: true,
            handler(newVal, oldVal) {
            },
        },
    },
    created() {
        if (this.$route && this.$route.query && this.$route.query.jwt) {
            this.setToken();
        }
    },
    mounted() {
        this.addListeners();
        this.onResize();
        this.setDefoults();
        this.getUserData();
        this.$crontab.addJob({
            name: "checkUserData",
            interval: {
                seconds: "/10",
            },
            auto_start: true,
            job: this.getUserData,
        });
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("user-data-changed", null);
        document.removeEventListener("scroll", this.scrollListener);
    },
    updated() {
        this.setDefoults();
    },
};
</script>

<style lang="scss">
.modal {
    .modal-signup {
        .notifications-modal-container {
            th.md-table-head:not(.md-table-cell-selection) {
                visibility: hidden !important;
            }
        }
    }
}
</style>

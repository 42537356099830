<template>
  <div>
    <canvas
      id="nu-chart"
      class="calculator-amortiz-chart"
      style="height: 26vw"
    ></canvas>
  </div>
</template>

<script>
import { Chart } from "chart.js";
import * as UtilityService from "@/plugins/UtilityService";

export default {
  name: "NuChart",
  data() {
    return {
      fontSize_11: 11,
      fontSize_12: 12,
      margin: 6,
      bodySpacing: 2,
      boxWidth: 40,
      pointRadius: 3,
      pointHoverRadius: 4,
      initChartData: [[], [], []],
      reactChartData: [[], [], []],
      reactChart: null,
      chartData: [[], [], []],
    };
  },

  methods: {
    getChartData(num) {
      return this.chartData && this.chartData[num]
        ? this.chartData[num]
        : this.initChartData;
    },
    addData(chart, data) {
      // chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset, i) => {
        dataset.data.push(data[i]);
      });
      chart.update();
    },

    removeData(chart) {
      chart.data.datasets.forEach((dataset) => {
        dataset.data.pop();
      });
      chart.update();
    },

    drawChart(dataFromCalc, labelsIn) {
      if (!dataFromCalc) {
        return;
      }
      if (window && window.innerWidth > 1366) {
        this.fontSize_11 = UtilityService.round(
          (0.8 * window.innerWidth) / 100,
          0
        );
        this.fontSize_12 = UtilityService.round(
          (0.9 * window.innerWidth) / 100,
          0
        );
        this.margin = UtilityService.round((0.44 * window.innerWidth) / 100, 0);
        this.bodySpacing = UtilityService.round(
          (0.15 * window.innerWidth) / 100,
          0
        );
        this.boxWidth = UtilityService.round(
          (2.93 * window.innerWidth) / 100,
          0
        );
        this.pointRadius = UtilityService.round(
          (0.22 * window.innerWidth) / 100,
          0
        );
        this.pointHoverRadius = UtilityService.round(
          (0.3 * window.innerWidth) / 100,
          0
        );
      }

      const nuChartData = {
        type: "line",
        data: {
          labels: labelsIn ? labelsIn : [],
          datasets: [
            {
              label: "Interest",
              yAxisID: "y-axis-1",
              data: dataFromCalc ? dataFromCalc[0] : this.chartData[0],
              backgroundColor: "rgba(0, 102, 255, .2)",
              borderColor: "#0066ff",
              borderWidth: 3,
            },
            {
              label: "Principal",
              yAxisID: "y-axis-2",
              data: dataFromCalc ? dataFromCalc[1] : this.chartData[1],
              backgroundColor: "rgba(51, 153, 51, .2)",
              borderColor: "#339933",
              borderWidth: 3,
            },
            {
              label: "Remaining",
              yAxisID: "y-axis-3",
              data: dataFromCalc ? dataFromCalc[2] : this.chartData[2],
              backgroundColor: "rgba(255, 102, 0, .2)",
              borderColor: "#ff6600",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return UtilityService.filter.format(value);
                  },
                  fontSize: this.fontSize_11,
                },
              },
              {
                id: "y-axis-2",
                type: "linear",
                display: true,
                position: "right",
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return UtilityService.filter.format(value);
                  },
                  fontSize: this.fontSize_11,
                },
              },
              {
                id: "y-axis-3",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return UtilityService.filter.format(value);
                  },
                  fontSize: this.fontSize_11,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  fontSize: this.fontSize_11,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
            mode: "label",
            bodyFontSize: this.fontSize_11,
            titleFontSize: this.fontSize_11,
            footerFontSize: this.fontSize_11,
            titleMarginBottom: this.margin,
            footerMarginTop: this.margin,
            xPadding: this.margin,
            yPadding: this.margin,
            bodySpacing: this.bodySpacing,
            callbacks: {
              title: function (tooltipItem, data) {
                return tooltipItem[0].xLabel;
              },
            },
          },
          legend: {
            display: true,
            labels: {
              fontSize: this.fontSize_12,
              boxWidth: this.boxWidth,
              padding: 2 * this.margin,
            },
          },
          title: { display: false },
        },
      };

      this.reactChartData = nuChartData;

      if (window.reactChart) {
        window.reactChart.destroy();
        const ctx = document.getElementById("nu-chart");
        window.reactChart = new Chart(ctx, nuChartData);
        this.removeData(window.reactChart);
        //this.addData(window.reactChart, dataFromCalc);
      }
    },
  },

  mounted() {
    //this.drawChart();
    const ctx = document.getElementById("nu-chart");
    window.reactChart = new Chart(ctx, this.reactChartData);
  },
};
</script>

<style lang="scss" scoped>
.calculator-amortiz-chart {
  width: 100% !important;
  height: 360px !important;
}

@media screen and (min-width: 1367px) {
  .calculator-amortiz-chart {
    height: 26.35vw !important;
  }
}
</style>

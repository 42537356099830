<template>
  <parallax class="section page-header header-filter" parallax-active="true">
    <div class="aligner">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item">
            <h2 class="title mt-0 text-center">
              AWARD-WINNING SOFTWARE TO<br />
              EVALUATE AND MANAGE NOTES
            </h2>

            <div class="video" v-if="loadVideo">
              <vue-aspect-ratio ar="16:9">
                <iframe
                  frameborder="0"
                  loading="lazy"
                  title="NoteUnlimited"
                  :style="iframeStyle"
                  :src="videoPath"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </vue-aspect-ratio>
            </div>
            <div class="video" v-if="!loadVideo">
              <LazyYoutubeVideo
                ref="youtube"
                :src="videoPath"
                :injectPlayerScript="injectPlayerScript"
                aspectRatio="16:9"
                :iframe-attributes="iframeAttr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </parallax>
</template>

<script>
import Vue from "vue";
import Parallax from "@/components/Parallax.vue";
import LazyYoutubeVideo from "vue-lazy-youtube-video";
Vue.component("LazyYoutubeVideo", LazyYoutubeVideo);

export default {
  components: { Parallax, LazyYoutubeVideo },

  data() {
    return {
      videoPath: `https://www.youtube.com/embed/BzzYsx8e6jg?enablejsapi=1`,
      injectPlayerScript: true,
      loadVideo: window.innerWidth > 768,
    };
  },
  computed: {
    iframeStyle() {
      return {
        width: "100%",
        height: "100%",
        display: "block",
        margin: "0 auto",
      };
    },
    iframeAttr() {
      return {
        allowfullscreen: true,
        frameborder: 0,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      };
    },
  },
};
</script>

<style lang="scss">
.y-video {
  background-color: #000;
  cursor: pointer;
}

.y-video__inner {
  position: relative;
}

.y-video__embed,
.y-video__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border-width: 0;
}

.y-video__media--type--img {
  object-fit: cover;
}

.y-video__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border-width: 0;
  background-color: transparent;
  width: 68px;
  height: 48px;
  cursor: pointer;
}

.y-video__button-shape {
  fill: #212121;
  fill-opacity: 0.8;
}

.y-video__button-icon {
  fill: #fff;
}

.y-video__button:focus {
  outline: 0;
}

.y-video__button:focus .y-video__button-shape {
  fill: red;
  fill-opacity: 1;
}

.y-video:hover .y-video__button-shape {
  fill: red;
  fill-opacity: 1;
}
</style>

<template>
  <transition :name="transition">
    <div
      tabindex="0"
      class="vld-overlay is-active"
      :class="{ 'is-full-page': isFullPage }"
      v-show="isActive"
      :aria-busy="isActive"
      aria-label="Loading"
      :style="blurStyle"
    >
      <div
        class="vld-background"
        @click.prevent="cancel"
        :style="bgStyle"
      ></div>
      <div class="vld-icon">
        <slot name="before" />
        <slot name="default">
          <component
            :is="loader"
            :color="color"
            :width="width"
            :height="height"
          />
        </slot>
        <slot name="after" />
      </div>
    </div>
  </transition>
</template>

<script>
import { removeElement, HTMLElement } from "./helpers.js";
import trapFocusMixin from "./trapFocusMixin.js";
import Loaders from "../loaders/index.js";

export default {
  name: "vue-loading",
  mixins: [trapFocusMixin],
  props: {
    active: Boolean,
    programmatic: Boolean,
    container: [Object, Function, HTMLElement],
    isFullPage: {
      type: Boolean,
      default: true,
    },
    enforceFocus: {
      type: Boolean,
      default: true,
    },
    lockScroll: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: "fade",
    },

    canCancel: {
      type: Boolean,
      default: false,
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "#215a72",
    },
    backgroundColor: {
      type: String,
      default: "#000000",
    },
    blur: {
      type: String,
      default: "3px",
    },
    opacity: {
      type: Number,
      default: 0.6,
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 5,
    },
    zIndex: Number,
    loader: {
      type: String,
      default: "spinner",
    },
  },
  data() {
    return {
      // Don't mutate the prop
      isActive: this.active,
      isFullPageClone: this.isFullPage,
    };
  },
  components: Loaders,
  beforeMount() {
    // Insert the component in DOM when called programmatically
    if (this.programmatic) {
      if (this.container) {
        this.isFullPageClone = false;
        this.container.appendChild(this.$el);
      } else {
        document.body.appendChild(this.$el);
      }
    }
  },
  mounted() {
    // Activate immediately when called programmatically
    if (this.programmatic) {
      this.isActive = true;
    }

    document.addEventListener("keyup", this.keyPress);
  },
  methods: {
    /**
     * Proxy to hide() method.
     * Gets called by ESC button or when click outside
     */
    cancel() {
      if (!this.canCancel || !this.isActive) return;
      this.hide();
      this.onCancel.apply(null, arguments);
    },
    /**
     * Hide and destroy component if it's programmatic.
     */
    hide() {
      this.$emit("hide");
      this.$emit("update:active", false);

      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false;
        setTimeout(() => {
          this.$destroy();
          removeElement(this.$el);
        }, 150);
      }
    },
    disableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.add("vld-shown");
      }
    },
    enableScroll() {
      if (this.isFullPage && this.lockScroll) {
        document.body.classList.remove("vld-shown");
      }
    },
    /**
     * Key press event to hide on ESC.
     *
     * @param event
     */
    keyPress(event) {
      if (event.key === "Escape") this.cancel();
    },
  },
  watch: {
    active(value) {
      this.isActive = value;
    },
    isActive(value) {
      if (value) {
        this.disableScroll();
      } else {
        this.enableScroll();
      }
    },
  },
  computed: {
    bgStyle() {
      return {
        background: this.backgroundColor,
        opacity: this.opacity,
      };
    },
    blurStyle() {
      return {
        zIndex: this.zIndex,
        backdropFilter: `blur(${this.blur})`,
      };
    },
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.keyPress);
  },
};
</script>

<style lang="scss">
.vld-shown {
  overflow: hidden;
}

.vld-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 9999;
}

.vld-overlay.is-active {
  display: flex;
}

.vld-overlay.is-full-page {
  z-index: 9999;
  position: fixed;
}

.vld-overlay .vld-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.vld-overlay .vld-icon,
.vld-parent {
  position: relative;
}
</style>

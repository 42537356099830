<template>
    <md-card class="md-card-login profile_card reset-card-style" :class="{ 'md-card-hidden': cardHidden }">
        <div class="md-layout">
            <div class="md-layout-item p-0">
                <form>
                    <!-- <md-card-header class="md-card-header-cyan m-0"> -->
                    <slot name="title"/>
                    <!-- </md-card-header> -->

                    <md-card-content class="mt-20 pl-15">
                        <slot name="description"/>
                        <slot name="error"/>
                        <slot name="inputs"/>
                        <slot name="checkbox"/>
                    </md-card-content>
                    <md-card-actions>
                        <slot name="footer"/>
                    </md-card-actions>
                </form>
                <div class="md-card-actions justify-content-between">
                    <slot name="subfooter"/>
                    <slot name="subfooter2"/>
                </div>
            </div>
        </div>
    </md-card>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
    name: "ProfileCard",
    mixins: [Mixins.DetectMobile],
    props: {
        headerColor: {
            type: String,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            cardHidden: true,
        };
    },
    beforeMount() {
        setTimeout(this.showCard, 400);
    },
    methods: {
        showCard: function () {
            this.cardHidden = false;
        },
        isHeaderHidden() {
            return this.hideHeader === true ? true : false;
        },
        getClass: function (headerColor) {
            return "md-card-header-" + headerColor + "";
        },
    },
};
</script>

<style lang="scss">
.modal {
    color: #3c4858;
}

.justify-content-between {
    justify-content: space-between !important;
}
</style>

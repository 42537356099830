import Vue from "vue";
import {
    ORIGINAL_TERM_TEXT,
    PDI_PAYMENT_TEXT,
    ORIGINAL_PRINCIPLE_BALANCE_TEXT,
    RATE_TEXT,
  } from "@/plugins/Constants";
  import moment from "moment";
  import * as UtilityService from "@/plugins/UtilityService";
import {UserDataHelper} from "@/api/helpers/index";


class NoteHelper extends Vue {

    noteCalculator(noteInputFormModel) {
        var principal = UtilityService.sanitizeInput(
          noteInputFormModel.originalPrincipleBalance
        );
        var term = UtilityService.sanitizeInput(noteInputFormModel.originalTerm);
        var interestRate = UtilityService.sanitizeInput(noteInputFormModel.rate);
        var payment = UtilityService.sanitizeInput(noteInputFormModel.pdiPayment);
        var isAllPresent =
          principal && term && interestRate && payment && term <= 480;
        if (!isAllPresent) {
          interestRate = interestRate / 1200;
          if (payment > 0) {
            payment = payment * -1;
          }
          if (interestRate && interestRate != 0) {
            if (principal && term && payment) {
              var newRate =
                UtilityService.calculateRateVlad(term, payment, principal) * 1200;
              noteInputFormModel.rate = UtilityService.round(newRate, 2);
              return RATE_TEXT;
            } else if (principal && term && interestRate) {
              var pay =
                (principal * interestRate) /
                (1 - Math.pow(1 / (1 + interestRate), term));
              noteInputFormModel.pdiPayment = UtilityService.round(pay, 2) * -1;
              return PDI_PAYMENT_TEXT;
            } else if (principal && interestRate && payment) {
              var newTerm = UtilityService.getNPER(
                interestRate,
                payment,
                principal
              );
              noteInputFormModel.originalTerm = UtilityService.round(newTerm, 0);
              return ORIGINAL_TERM_TEXT;
            } else if (term && interestRate && payment) {
              var newPrinciple = UtilityService.getPV(
                interestRate,
                term,
                payment
              );
              noteInputFormModel.originalPrincipleBalance = UtilityService.round(
                newPrinciple,
                2
              );
              return ORIGINAL_PRINCIPLE_BALANCE_TEXT;
            }
          } else {
            if (principal && term && payment) {
              var newRate =
                UtilityService.calculateRateVlad(term, payment, principal) * 1200;
              noteInputFormModel.rate = UtilityService.round(newRate, 2);
              return RATE_TEXT;
            } else {
              if (payment < 0) payment = -1 * payment;
              if (principal && term) {
                var pay = principal / term;
                noteInputFormModel.pdiPayment = UtilityService.round(pay, 2) * -1;
                return PDI_PAYMENT_TEXT;
              } else if (principal && payment) {
                var newTerm = principal / payment;
                noteInputFormModel.originalTerm = Math.ceil(newTerm);
                return ORIGINAL_TERM_TEXT;
              } else if (term && payment) {
                var newPrinciple = term * payment;
                noteInputFormModel.originalPrincipleBalance =
                  UtilityService.round(newPrinciple, 2);
                return ORIGINAL_PRINCIPLE_BALANCE_TEXT;
              }
            }
          }
        }
    }

    FV(rate, periods, payment, value, type) {
        // Credits: algorithm inspired by Apache OpenOffice
        value = value || 0;
        type = type || 0;
        if (rate)
            rate = parseFloat(rate);
        if (rate)
            rate = rate / 1200;
        if (periods)
            periods = parseInt(periods);
        if (periods > 1)
            periods = periods - 1;
        if (payment)
            payment = parseFloat(payment);
        if (payment)
            payment = Math.abs(payment);
        value = parseFloat(value);
        type = parseFloat(type);

        // Return future value
        var result;
        if (rate === 0) {
            result = value - payment * periods;
        } else {
            var term = Math.pow(1 + rate, periods);
            if (type === 1) {
                result = value * term - payment * (1 + rate) * (term - 1) / rate;
            } else {
                result = value * term - payment * (term - 1) / rate;
            }
        }
        if (result < 0)
            result = 0;
        return result;
    }

    calcUnpaidBalance(note) {
        if (note.maturityDate && note.firstPaymentDate &&
            note.remainingPayment) {
            var paidPaymentsCount = Math.ceil(moment(note.maturityDate, "MM/DD/YYYY").diff(
                    moment(note.firstPaymentDate, "MM/DD/YYYY"), 'months', true)) + 1 -
                parseInt(note.remainingPayment);
            note.upb = FV(note.rate,
                paidPaymentsCount, note.pdiPayment,
                note.originalPrincipleBalance, 1);
        }
    }

    getPV(rate, periods, payment, future, type) {
		future = future || 0;
		type = type || 0;
		if (payment > 0) {
			payment = -1 * payment;
		}

		// Return present value
		if (rate === 0) {
			return Math.abs(payment) * periods - future;
		} else {
			return (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods);
		}
	}


    calcRemainingPayments(maturityDate, nextPeriodEndDate) {
        if (nextPeriodEndDate && maturityDate) {
            return Math.ceil(moment(maturityDate, "MM/DD/YYYY").diff(
                moment(nextPeriodEndDate, "MM/DD/YYYY"), 'months', true)) + 1;
        }else{
            return null;
        }
    }

    getDateFromDateComparison(dateString1, dateString2) {
        var date1 = new Date(dateString1);
        var date2 = new Date(dateString2);

        if (date1.getDate() === date2.getDate()) {
          // If both dates are equal, return date1
          return date1;
        } else if (date2 > date1) {
          // If the second date is greater than the current one
          // Get the day of the month from the first date
          var dayOfMonth = date1.getDate();
          var newDate = new Date(date2.getFullYear(), date2.getMonth() + 1, dayOfMonth);

          // Create a new date by adding 1 month to the second date and setting the day of the month from the first date
          var formattedDate = (newDate.getMonth() + 1).toString().padStart(2, '0') + '/' + newDate.getDate().toString().padStart(2, '0') + '/' + newDate.getFullYear();
          return formattedDate;
        }
      }

    isMyNote(note) {
        if (!note) {
            return true;
        }
        let user = UserDataHelper.getUser();
        let res = note.userId == user.userId;

        return res;
    }

}

export default new NoteHelper();
import Vue from "vue";

class AuthHelper extends Vue {
  encodePassword(password) {
    return window.btoa(
      encodeURIComponent(password).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(_, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }
}

export default new AuthHelper();

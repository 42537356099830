<template>
  <parallax
    class="page-header header-filter pt-0 header-small"
    parallax-active="true"
  >
    <div class="container m-auto">
      <div class="md-layout">
        <div class="md-layout-item">
          <h1 class="title mt-0 text-center">
            {{ text }}
          </h1>
        </div>
      </div>
    </div>
  </parallax>
</template>

<script>
import Parallax from "@/components/Parallax.vue";
export default {
  components: { Parallax },

  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
</style>

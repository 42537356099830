<template>
    <div class="wrapper wrapper-full-page">
        <parallax-simple-section :text="$t('calculator-page.title')"></parallax-simple-section>
        <div class="main main-raised">
            <div class="section">
                <div class="container">
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <h5 class="description text-preline">
                                {{ $t("calculator-page.description") }}
                            </h5>
                        </div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-45 md-medium-size-80 md-small-size-100 mx-auto">
                            <h4 class="title text-center">{{ $t("calculator-page.enter-three-fields") }}</h4>
                            <form class="mt-30">
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-80 md-medium-size-80 md-xsmall-size-65">
                                        <md-field class="md-focused" :class="{'md-error': $v.noteInputFormModel.originalPrincipleBalance.$error,}">
                                            <label>Balance</label>
                                            <span class="md-prefix">$</span>
                                            <md-input :placeholder="$t('calculator-page.balance')" id="originalPrincipleBalance" class="md-focused"
                                                ref="originalPrincipleBalance" @keypress="isDecimal" @keyup.enter="onOriginalPrincipleBalance"
                                                v-model.trim="$v.noteInputFormModel.originalPrincipleBalance.$model">
                                            </md-input>
                                        </md-field>
                                        <div id="calculatorBalanceError" class="text-danger" v-if="!$v.noteInputFormModel.originalPrincipleBalance.decimal">
                                            {{ $t("validation.balance-numeric") }}
                                        </div>
                                    </div>
                                    <div class="md-layout-item md-size-20 md-medium-size-20 md-xsmall-size-35 d-flex px-0 align-items-center justify-content-right">
                                        <md-button class="md-just-icon md-cyan-900" @click="onOriginalPrincipleBalance">
                                            <md-icon class="white">search</md-icon>
                                        </md-button>
                                        <md-button class="md-just-icon md-cyan-900" @click="onClearOriginalPrincipleBalance">
                                            <md-icon class="white">close</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-80 md-medium-size-80 md-xsmall-size-65">
                                        <md-field class="md-focused" :class="{ 'md-error': $v.noteInputFormModel.rate.$error }">
                                            <label>Rate</label>
                                            <md-input v-model.trim="$v.noteInputFormModel.rate.$model" id="calculatorRate"
                                                :placeholder="$t('calculator-page.rate')" @keyup.enter="onRate" @keypress="isDecimal"/>
                                            <span class="md-suffix">%</span>
                                        </md-field>
                                        <div id="calculatorRateError" class="text-danger" v-if="!$v.noteInputFormModel.rate.decimal">
                                            {{ $t("validation.rate-numeric") }}
                                        </div>
                                    </div>
                                    <div class="md-layout-item md-size-20 md-medium-size-20 md-xsmall-size-35 d-flex px-0 align-items-center justify-content-right">
                                        <md-button class="md-just-icon md-cyan-900" @click="onRate">
                                            <md-icon class="white">search</md-icon>
                                        </md-button>
                                        <md-button class="md-just-icon md-cyan-900" @click="onClearRate" >
                                            <md-icon class="white">close</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-80 md-medium-size-80 md-xsmall-size-65">
                                        <md-field class="md-focused" :class="{'md-error': $v.noteInputFormModel.pdiPayment.$error,}">
                                            <label>Payment</label>
                                            <span class="md-prefix">$</span>
                                            <md-input v-model.trim="$v.noteInputFormModel.pdiPayment.$model" id="calculatorPayment" :placeholder="$t('calculator-page.payment')"
                                                @keyup.enter="onPdiPayment" @keypress="isDecimal" @change="verifyPayment">
                                            </md-input>
                                        </md-field>
                                        <div id="calculatorPaymentError" class="text-danger" v-if="!$v.noteInputFormModel.pdiPayment.decimal" >
                                            {{ $t("validation.payment-decimal") }}
                                        </div>
                                    </div>
                                    <div class="md-layout-item md-size-20 md-medium-size-20 md-xsmall-size-35 d-flex px-0 align-items-center justify-content-right" >
                                        <md-button class="md-just-icon md-cyan-900" @click="onPdiPayment">
                                            <md-icon class="white">search</md-icon>
                                        </md-button>
                                        <md-button class="md-just-icon md-cyan-900" @click="onClearPdiPayment">
                                            <md-icon class="white">close</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="md-layout">
                                    <div class="md-layout-item md-size-80 md-medium-size-80 md-xsmall-size-65" >
                                        <md-field class="md-focused" :class="{'md-error': $v.noteInputFormModel.originalTerm.$error,}">
                                            <label>{{ $t("calculator-page.term") }} </label>
                                            <md-input v-model.trim="$v.noteInputFormModel.originalTerm.$model" id="calculatorOriginalTerm"
                                                @keyup.enter="onOriginalTerm" :placeholder="$t('calculator-page.term')" type="number"
                                                min="60" max="480" md-counter="false"></md-input>
                                            <span class="md-suffix">Months</span>
                                        </md-field>
                                        <div id="calculatorOriginalTermError" class="text-danger" v-if="!$v.noteInputFormModel.originalTerm.numeric">
                                            {{ $t("validation.term-numeric") }}
                                        </div>
                                        <div id="calculatorOriginalTermErrorBetween" class="text-danger" v-if="!$v.noteInputFormModel.originalTerm.between">
                                            Value must be less than
                                            {{$v.noteInputFormModel.originalTerm.$params.between.min}}
                                            or equal to
                                            {{$v.noteInputFormModel.originalTerm.$params.between.max}}
                                        </div>
                                    </div>
                                    <div class="md-layout-item md-size-20 md-medium-size-20 md-xsmall-size-35 d-flex px-0 align-items-center justify-content-right">
                                        <md-button class="md-just-icon md-cyan-900" @click="onOriginalTerm" >
                                            <md-icon class="white">search</md-icon>
                                        </md-button>
                                        <md-button class="md-just-icon md-cyan-900" @click="onClearOriginalTerm">
                                            <md-icon class="white">close</md-icon>
                                        </md-button>
                                    </div>
                                </div>
                                <div class="md-layout md-alignment-center-right">
                                    <md-button @click="populateNoteInputModelFromJS(null)" id="calculatorCalculate" class="md-cyan-900 button-border-cyan-900 ml-20">
                                        {{ $t("calculator-page.calculate") }}
                                    </md-button>
                                    <md-button id="calculatorClearAll" class="md-white button-border-light-gray" @click="clearAllCalcFields">
                                        {{ $t("calculator-page.clear-all") }}
                                    </md-button>
                                    <div class="md-layout-item md-size-100 pt-20" v-if="hasValidData">
                                        <p id="calculatorTotalPayment" class="my-0 ml-0">{{ totalPaymentFunc }}</p>
                                        <p id="calculatorMonthlyPayment" class="ml-0">{{ monthlyPaymentFunc }}</p>
                                        <div>
                                            <NuChart ref="NuChart"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="md-layout-item md-size-55 md-medium-size-80 md-small-size-100 mx-auto">
                            <h4 class="title text-center">
                                {{ $t("calculator-page.amortization-schedule-breakdown") }}
                            </h4>
                            <div class="md-layout" v-if="hasValidData">
                                <div class="md-layout-item md-size-50 md-xsmall-size-100 mx-auto">
                                    <p id="totalPrincipalPayments" class="my-0 ml-15">{{ totalPrincipalPayments }}</p>
                                    <p id="totalInterestPayments" class="mb-0 ml-15">{{ totalInterestPayments }}</p>
                                </div>
                                <div class="md-layout-item d-flex md-size-50 md-xsmall-size-100 mx-auto">
                                    <p class="my-0">Start date</p>
                                    <div class="ml-20-flex">
                                        <md-field class="py-0">
                                            <month-picker-input :no-default="true" :show-year="true" :default-month="user.details.startMonth"
                                                :default-year="user.details.startYear" :input-pre-filled="true" @change="showDate"></month-picker-input>
                                        </md-field>
                                    </div>
                                </div>
                            </div>
                            <div class="md-layout mx-0">
                                <div class="md-layout-item ml-20 md-size-100 overflow-auto">
                                    <md-table table-header-color="white" v-model="amortizationItems" v-if="hasValidData && amortizationItems" md-card
                                        md-height="42.46vw" md-fixed-header class="mt-15 shadow-sm mb-5">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                                            <md-table-cell md-label="#" md-numeric>
                                                {{item.month + 1}}
                                            </md-table-cell>
                                            <md-table-cell md-label="Date">
                                                {{getDate(item.month)}}
                                            </md-table-cell>
                                            <md-table-cell md-label="Interest">
                                                {{formatCurrency(item.monthlyInterest)}}
                                            </md-table-cell>
                                            <md-table-cell md-label="Principal">
                                                {{formatCurrency(item.monthlyPrincipal)}}
                                            </md-table-cell>
                                            <md-table-cell md-label="Balance">
                                                {{formatCurrency(item.remainingBalance)}}
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                    <div v-if="!hasValidData" class="button-border-cyan-900 mt-10 mb-20 d-flex">
                                        <md-empty-state id="calculatorEmptyState" md-label="No solution"></md-empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-error :showModal="calcModal" :message="$t('calculator-page.calc-validation')" @close="calcModal = false" />
        <modal-auth v-if="signInUpModal" :is-opened="signInUpModal" @close="closeAuthModal" @redirect="redirect()" :needTrialForSignUp="false"></modal-auth>
    </div>
</template>

<script>
import ParallaxSimpleSection from "./sections/universal/ParallaxSimpleSection.vue";
import NuChart from "@/views/components/NuChart.vue";
import ModalError from "@/views/modal/ModalError.vue";
import moment from "moment";
import MonthPickerInput from "@/views/components/MonthPickerInput.vue";
import {numeric, decimal, between} from "vuelidate/lib/validators";
import * as UtilityService from "@/plugins/UtilityService";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import {
    ORIGINAL_TERM_TEXT,
    PDI_PAYMENT_TEXT,
    ORIGINAL_PRINCIPLE_BALANCE_TEXT,
    RATE_TEXT, ROUTER_LINKS,
} from "@/plugins/Constants";
import DateFormatter from "@/api/helpers/DateFormatter";
import NoteHelper from "@/api/helpers/NoteHelper";
import Mixins from "@/plugins/basicMixins";


export default {
    components: {
        ParallaxSimpleSection,
        NuChart,
        MonthPickerInput,
        ModalError,
        ModalAuth: () => import("@/views/modal/ModalAuth.vue"),
    },
    mixins: [
        Mixins.DisablePageOverflow,
    ],
    bodyClass: "calculator-page",
    data() {
        var signInUpModal = false;
        var firstPageLoad = true;
        return {
            hasValidData: true,
            outputField: null,
            calcModal: false,
            showTableData: false,
            originalPrincipleBalanceText: ORIGINAL_PRINCIPLE_BALANCE_TEXT,
            rateText: RATE_TEXT,
            pdiPaymentText: PDI_PAYMENT_TEXT,
            originalTermText: ORIGINAL_TERM_TEXT,
            totalPayment: 0,
            monthlyPayment: 0,
            totalPrincipal: 0,
            totalInterest: 0,
            amortizationItems: null,
            noteInputFormModel: {
                originalPrincipleBalance: "100000",
                rate: "6",
                originalTerm: "360",
                pdiPayment: "",
            },
            user: {
                details: {
                    startYear: parseInt(moment(new Date()).format("YYYY")),
                    startMonth: parseInt(moment(new Date()).format("MM")),
                    loanAmount: "100000",
                    interestRate: "6",
                    loanTerm: "360",
                },
            },
            calcMainClass: [
                "md-layout-item",
                "md-xsmall-size-100",
                "md-small-size-66",
                "md-medium-size-40",
                "md-large-size-40",
                "md-xlarge-size-33",
                "mx-auto",
            ],
            isCalculate: false,
            signInUpModal,
            firstPageLoad,
            routing: {
                sendEmailPath: ROUTER_LINKS.send_email.path,
            },
        };
    },
    validations: {
        noteInputFormModel: {
            originalPrincipleBalance: {
                decimal,
            },
            rate: {
                decimal,
            },
            originalTerm: {
                numeric,
                between: between(60, 480),
            },
            pdiPayment: {
                decimal,
            },
        },
    },
    computed: {
        totalInterestPayments() {
            return `Total interest payments: ${this.totalInterest}`;
        },
        totalPaymentFunc() {
            return `Over 30 years you'll pay: ${this.totalPayment}`;
        },
        monthlyPaymentFunc() {
            return `Based on an estimated monthly payment of ${this.monthlyPayment}`;
        },
        totalPrincipalPayments() {
            return `Total principal payments: ${this.totalPrincipal}`;
        },
    },
    methods: {
        checkAuth() {
            if (!this.getToken()) {
                return false;
            }
            return true;
        },

        getToken() {
            return sessionStorage.getItem("token");
        },

        openAuthModal() {
            this.disableScroll();
            this.signInUpModal = true;
        },

        closeAuthModal() {
            this.enableScroll();
            this.signInUpModal = false;
        },

        redirect() {
            this.closeAuthModal();
            if (!this.getToken()) {
                this.$router.push(this.routing.sendEmailPath);
            }
        },

        showDate(date) {
            this.user.details.startMonth = date.monthIndex;
            this.user.details.startYear = date.year;
            this.calculate();
            this.firstPageLoad = false;
        },

        focusInput() {
            this.$refs.originalPrincipleBalance.$el.focus();
        },

        isNumber(event) {
            const charCode = String.fromCharCode(event.keyCode);
            if (!/^[0-9]+$/.test(charCode)) {
                event.preventDefault();
            }
        },

        isDecimal(event) {
            const charCode = String.fromCharCode(event.keyCode);
            if (!/([\d]+[.][\d]+)|([\d]+)|([\.\+\-\*\/\^]+)|([\(\)])/.test(charCode)) {
                event.preventDefault();
            }
        },

        formatCurrency(val) {
            const filterTable = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
            });
            return filterTable.format(val);
        },

        formatCurrencyWithoutSymbol(val) {
            const filterTable = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "code",
                minimumFractionDigits: 2,
            });
            return filterTable
                .format(val)
                .replace(/[USD]/gi, "")
                .replace(/(\.+\d{2})/, "")
                .trimLeft();
        },

        calculate() {
            if (this.isCalculate == true) {
                return;
            }
            this.hasValidData = true;
            this.isCalculate = true;
            var rate = this.noteInputFormModel.rate;
            this.user.details.interestRate = rate;
            var n = function (t) {
                return +t.toFixed(2);
            };
            var monthlyPI = function (t, e, r, i) {
                var a, o, u;
                return 0 === e
                    ? n(t / r)
                    : ((a = e / 1200),
                        (o = Math.pow(1 + a, r)),
                        (u = (t * a * o) / (o - 1)),
                        i ? u : n(u));
            };
            var amortizationF = function (t, e, r) {
                var i,
                    a,
                    o,
                    u = e / 1200,
                    s = monthlyPI(t, e, r),
                    l = t,
                    c = 0,
                    f = [],
                    d = 0,
                    h = 0;
                for (i = 0; i < r; i += 1)
                    (a = n(l * u)),
                        (c = n(c + a)),
                        i === r - 1
                            ? ((s = n(l + a)), (o = l), (l = 0))
                            : ((o = n(s - a)), (l = n(l - o))),
                        (d += a),
                        (h += o),
                    i - 12 >= 0 &&
                    ((d -= f[i - 12].monthlyInterest),
                        (h -= f[i - 12].monthlyPrincipal)),
                        f.push({
                            month: i,
                            monthlyInterest: rate == 0 ? 0 : a,
                            totalInterest: rate == 0 ? 0 : c,
                            monthlyPrincipal: rate == 0 ? n(t / r) : o,
                            totalPrincipal: rate == 0 ? n(s * r) : n(t - l),
                            remainingBalance: rate == 0 ? t - (i + 1) * n(t / r) : l,
                            twelveMonthsInterest: d,
                            twelveMonthsPrincipal: h,
                        });
                return f;
            };
            var i = function (t) {
                if (t.term == "5") t.term = "30";
                var e = t.term;

                var n = amortizationF(t.amount, t.rate, e);
                if (n.length > 0) {
                    var r = n[n.length - 1].totalInterest,
                        i = n[n.length - 1].totalPrincipal,
                        a = r + n[n.length - 1].totalPrincipal,
                        o = n[0].monthlyInterest + n[0].monthlyPrincipal;
                }
                return {
                    totalInterest: r,
                    totalPayment: a,
                    totalPrincipal: i,
                    loanAmount: t.amount,
                    rate: t.rate,
                    amortization: n,
                    program: t.term,
                    monthlyPayment: o,
                    years: e,
                };
            };
            var t = {
                term: this.user.details.loanTerm,
                amount: this.user.details.loanAmount,
                rate: this.user.details.interestRate,
            };
            var amortValues = i(t);
            if (
                !amortValues ||
                !amortValues.amortization ||
                amortValues.amortization.length <= 0
            ) {
                this.isCalculate = false;
                this.hasValidData = false;
                return;
            }
            let step = Math.ceil(parseInt(t.term) / 15);
            var marray = [1];
            var labels = [[1, "month"]];
            for (var i = 2; i <= 15; i++) {
                if (step * i <= t.term) {
                    marray.push(step * i);
                    labels.push([step * i, "months"]);
                }
            }

            if (labels.length < 15) {
                marray.push(t.term);
                labels.push([t.term, "months"]);
            }

            var data = [[], [], []];
            for (const obj of amortValues.amortization) {
                if (marray.indexOf(obj.month + 1) != -1) {
                    data[0].push(obj.monthlyInterest);
                    data[1].push(obj.monthlyPrincipal);
                    data[2].push(obj.remainingBalance);
                }
            }
            data[0].push(
                amortValues.amortization[amortValues.amortization.length - 1]
                    .monthlyInterest
            );
            data[1].push(
                amortValues.amortization[amortValues.amortization.length - 1]
                    .monthlyPrincipal
            );
            data[2].push(
                amortValues.amortization[amortValues.amortization.length - 1]
                    .remainingBalance
            );

            this.amortizationItems = amortValues.amortization;
            this.totalPrincipal = this.formatCurrency(amortValues.totalPrincipal);
            this.totalInterest = this.formatCurrency(amortValues.totalInterest);
            this.totalPayment = this.formatCurrency(amortValues.totalPayment);
            this.monthlyPayment = this.formatCurrency(amortValues.monthlyPayment);
            this.noteInputFormModel.originalPrincipleBalance = amortValues.loanAmount;
            this.noteInputFormModel.rate = this.user.details.interestRate;
            this.noteInputFormModel.originalTerm = this.user.details.loanTerm;
            if (this.$refs.NuChart) {
                this.$refs.NuChart.drawChart(data, labels);
            }
            this.isCalculate = false;
        },

        /* get date by selected month and year and number of payment*/
        getDate(index) {
            var d = new Date(
                this.user.details.startYear,
                this.user.details.startMonth,
                1
            );
            d = d.setMonth(d.getMonth() + (index - 1));
            return DateFormatter.UTCToMonthYear(d);
        },
        hasAllFields(model) {
            var count = 0;
            if (
                model.originalPrincipleBalance &&
                model.originalPrincipleBalance != ""
            )
                count++;
            if (model.originalTerm && model.originalTerm != "") count++;
            if (model.rate && model.rate != "") count++;
            if (model.pdiPayment && model.pdiPayment != "") count++;
            return count >= 3;
        },
        hasAllInputs(model) {
            var count = 0;
            if (
                model.originalPrincipleBalance &&
                model.originalPrincipleBalance != ""
            )
                count++;
            if (model.originalTerm && model.originalTerm != "") count++;
            if (model.rate && model.rate != "" && model.rate <= 480) count++;
            if (model.pdiPayment && model.pdiPayment != "") count++;
            return count == 3;
        },
        populateNoteInputModelFromJS(inputField) {
            if (!this.checkAuth() && !this.firstPageLoad) {
                this.openAuthModal();
                return;
            } else {
                if (!this.noteInputFormModel) {
                    return;
                }
                var isAllPresent = this.hasAllFields(this.noteInputFormModel);
                if (isAllPresent && inputField != null) {
                    this.noteInputFormModel[inputField] = "";
                }
                isAllPresent = this.hasAllInputs(this.noteInputFormModel);
                if (!isAllPresent) {
                    this.calcModal = true;
                } else {
                    this.outputField = NoteHelper.noteCalculator(this.noteInputFormModel);
                    this.user.details.loanAmount =
                        this.noteInputFormModel.originalPrincipleBalance;
                    this.user.details.interestRate = this.noteInputFormModel.rate;
                    this.user.details.loanTerm = this.noteInputFormModel.originalTerm;
                    this.calculate();
                    //this.noteInputFormModel.originalTerm = this.user.details.loanTerm;
                }
            }
        },
        clearCalcField(inputField) {
            if (this.noteInputFormModel && this.noteInputFormModel[inputField]) {
                this.noteInputFormModel[inputField] = "";
            }
        },
        clearAllCalcFields() {
            this.hasValidData = false;
            this.noteInputFormModel.originalPrincipleBalance =
                this.noteInputFormModel.rate =
                    this.noteInputFormModel.originalTerm =
                        this.noteInputFormModel.pdiPayment =
                            "";
        },
        verifyPayment() {
            if (
                this.noteInputFormModel.pdiPayment &&
                parseFloat(this.noteInputFormModel.pdiPayment) > 0
            ) {
                this.noteInputFormModel.pdiPayment =
                    -1 * parseFloat(this.noteInputFormModel.pdiPayment);
            }
        },
        async onOriginalPrincipleBalance() {
            this.populateNoteInputModelFromJS(ORIGINAL_PRINCIPLE_BALANCE_TEXT);
        },
        async onRate() {
            this.populateNoteInputModelFromJS(RATE_TEXT);
        },
        async onOriginalTerm() {
            this.populateNoteInputModelFromJS(ORIGINAL_TERM_TEXT);
        },
        async onPdiPayment() {
            this.populateNoteInputModelFromJS(PDI_PAYMENT_TEXT);
        },
        onClearOriginalPrincipleBalance() {
            this.clearCalcField(ORIGINAL_PRINCIPLE_BALANCE_TEXT);
        },
        onClearRate() {
            this.clearCalcField(RATE_TEXT);
        },
        onClearOriginalTerm() {
            this.clearCalcField(ORIGINAL_TERM_TEXT);
        },
        onClearPdiPayment() {
            this.clearCalcField(PDI_PAYMENT_TEXT);
        },
    },
    mounted() {
        this.focusInput();
    },
    created() {
        this.user.details.loanAmount =
            this.noteInputFormModel.originalPrincipleBalance;
        this.user.details.interestRate = this.noteInputFormModel.rate;
    },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
    //display: flex;
    justify-content: center !important;
}

// p {
//   font-size: 0.75rem;
//   margin: 0 0 10px;
// }

.md-has-textarea + .md-layout {
    margin-top: 15px;
}

.md-field {
    .md-input {
        &.month-picker-input {
            padding-top: 0 !important;
        }
    }
}
</style>

<template>
  <md-card
    class="md-card-nav-tabs"
    :class="{
      'no-label': noLabel,
      'md-card-plain': tabsPlain,
      'md-card-dialog': cardDialog,
      'mb-0': marginHeader,
    }"
  >
    <md-card-content
      class="pb-0"
      :class="{ 'px-0 md-card-header': marginHeader }"
    >
      <slot name="content" />
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "NavTabsCard",
  props: {
    noLabel: Boolean,
    tabsPlain: Boolean,
    marginHeader: Boolean,
    cardDialog: Boolean,
  },
};
</script>

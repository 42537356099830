<template>
  <div class="wrapper wrapper-full-page">
    <parallax-section> </parallax-section>
    <div class="main main-raised">
      <cards-section></cards-section>
      <div class="section pt-0">
        <div class="container">
          <div class="md-layout md-layout-item">
            <div class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto">
              <h2
                class="text-center title text-uppercase"
                id="schedule-webinar"
              >
                Schedule Webinar
              </h2>
              <form class="mt-30">
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-60 md-large-size-70 md-medium-size-80 md-small-size-100 md-xsmall-size-100 mx-auto"
                  >
                    <md-field>
                      <md-select-extended
                        id="promoCode"
                        v-model="topicFormData.promoCode"
                        name="promoCode"
                        @md-selected="onChangeTopics"
                      >
                        <md-option
                          v-for="topic in topics"
                          :value="topic.topicName"
                          :key="topic.topicName"
                          class="custom-class"
                        >
                          {{ topic.topicName }}</md-option
                        >
                      </md-select-extended>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-60 md-large-size-70 md-medium-size-80 md-small-size-100 md-xsmall-size-100 mx-auto"
                  >
                    <md-field
                      :class="{
                        'md-error': $v.topicFormData.displayName.$error,
                      }"
                    >
                      <md-input
                        v-model.trim="$v.topicFormData.displayName.$model"
                        placeholder="Enter your name"
                        type="text"
                      ></md-input>
                    </md-field>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.topicFormData.displayName.required &&
                        $v.topicFormData.displayName.$error
                      "
                    >
                      {{ $t("validation.required-name") }}
                    </div>
                  </div>
                </div>
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-60 md-large-size-70 md-medium-size-80 md-small-size-100 md-xsmall-size-100 mx-auto"
                  >
                    <md-field
                      :class="{ 'md-error': $v.topicFormData.email.$error }"
                    >
                      <md-input
                        v-model.trim="$v.topicFormData.email.$model"
                        placeholder="Enter your email"
                        @keyup.enter="onEnter"
                        type="email"
                      ></md-input>
                    </md-field>
                    <div
                      class="text-danger"
                      v-if="
                        !$v.topicFormData.email.required &&
                        $v.topicFormData.email.$error
                      "
                    >
                      {{ $t("validation.required-email") }}
                    </div>
                    <div
                      class="text-danger"
                      v-if="!$v.topicFormData.email.email"
                    >
                      {{ $t("validation.valid-email") }}
                    </div>
                  </div>
                </div>
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-100 mt-2 mx-auto text-center"
                  >
                    <md-button class="md-cyan-900" @click="sendWebinar"
                      >Submit request</md-button
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="section pt-0">
        <div class="container">
          <h2 class="text-center title text-uppercase">
            Blog
          </h2>
          <blog-section/>
        </div>
      </div>
      <testimonials-section 
        :testimonials="testimonials"
      />
    </div>
    <modal-announcements
      :notes="notes"
      :open="announcementsModal"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ParallaxSection from "@/views/sections/universal/ParallaxSection.vue";
import MdSelectExtended from "@/views/components/MdSelectExtended.vue";
import { required, email } from "vuelidate/lib/validators";
import Mixins from "@/plugins/basicMixins";
import ApiLanding from "@/api/methods/ApiLanding";
import { ErrorHelper } from "@/api/helpers";

export default {
  components: {
    ParallaxSection,
    TestimonialsSection: () =>
      import("./sections/universal/TestimonialsSection.vue"),
    CardsSection: () => import("./sections/universal/CardsSection.vue"),
    BlogSection: () => import("./sections/universal/BlogSection.vue"),
    ModalAnnouncements: () => import("@/views/modal/ModalAnnouncements.vue"),
    MdSelectExtended,
  },
  mixins: [Mixins.ShowToastr, Mixins.DisablePageOverflow],
  bodyClass: "landing-page",
  name: "Index",
  data() {
    return {
      name: null,
      email: null,
      message: null,
      selectedTopic: null,
      personEmail: null,
      published: "Y",
      sequenceOrder: 1,
      topics: [],
      topicFormData: {
        displayName: null,
        email: null,
        promoCode: this.selectedTopic,
      },
      testimonials: [],
      announcementsModal: false,
      notes: null,
    };
  },
  validations: {
    // The field name must match the field in data
    topicFormData: {
      displayName: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    sanitize(html) {
      return sanitizeHtml(html);
    },
    onChangeTopics(v) {
      this.selectedTopic = v;
    },

    webinarInfoAlert() {
      this.showSuccess(this.name, this.$t("messages.webinar"));
    },

    getTopics(response) {
      this.topics = response.data.webinarTopics;
      this.selectedTopic = this.topics[0].topicName;
      this.topicFormData.promoCode = this.topics[0].topicName;
    },
    getData() {
      let token = sessionStorage.getItem("token");
      ApiLanding.getHome(token)
        .then((response) => {
          this.getTopics(response);
          this.getTestimonials(response);
          if ( token && response.data.announcements && response.data.announcements.releaseNotes && response.data.announcements.releaseNotes.length > 0) {
            this.notes = response.data.announcements;
            this.showAnnouncementsModal();
          }
        })
        .catch((e) => {
          let msg = ErrorHelper.message(e, null);
          if (msg) {
            this.showError(this.name, msg);
          }
        });
    },
    showAnnouncementsModal() {
      this.disableScroll();
      this.announcementsModal = true;
    },
    closeModal() {
      this.enableScroll();
      this.announcementsModal = false;
    },
    sendWebinar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const { displayName, email, promoCode } = this.topicFormData;

      var data = {
        displayName: displayName,
        email: email,
        promoCode: promoCode,
      };
      ApiLanding.requestWebinar(data)
        .then((response) => {
          this.webinarInfoAlert();
        })
        .catch((e) => {
          this.showError(
            this.name,
            ErrorHelper.message(e, this.$t("messages.webinar-error"))
          );
        });
    },
    onEnter() {
      this.sendWebinar();
    },
    getTestimonials(response) {
      this.testimonials = response.data.testimonials;
    },
  },
  computed: {
    buttonDisabled() {
      const { displayName, email } = this.topicFormData;
      return (
        !displayName ||
        !email ||
        this.$v.topicFormData.displayName.$invalid ||
        this.$v.topicFormData.email.$invalid
      );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss">
.md-card-actions.text-center {
  justify-content: center !important;
}
.md-has-textarea + .md-layout {
  margin-top: 15px;
}
.md-layout {
  margin-left: 0;
  margin-right: 0;
}
.mt-2 {
  margin-top: 1.5rem;
}
</style>

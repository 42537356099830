<template>
  <div>
    <div v-for="item in items" :key="item" class="md-collapse">
      <div class="md-collapse-label">
        <p class="py-0">
          {{ item }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    items: Array,
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
}
</style>

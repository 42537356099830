import Vue from 'vue';
import Vuex from 'vuex';
import UserInfo from "@/store/modules/UserInfo";
import SubscriptionAndAddons from "./modules/SubscriptionAndAddons";
import ModalWindow from "@/store/modules/ModalWindow";
import Payment from "@/store/modules/Payment";
import OffersSeller from "@/store/modules/OffersSeller";
import OffersBuyer from "@/store/modules/OffersBuyer";
import Messaging from "@/store/modules/Messaging";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ModalWindow,
    UserInfo,
    Payment,
    SubscriptionAndAddons,
    Messaging,
    OffersSeller,
    OffersBuyer,
  }
});
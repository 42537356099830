import SubscriptionPeriod from "@/enums/SubscriptionPeriod";
import {SubscriptionHelper} from "@/api/helpers";

const setPaymentInfoToStorage  = (state) => {
  localStorage.setItem(
    "payment",
    JSON.stringify({
      group: state.selectedSubscription,
      addons: state.allSelectedAddons,
      period: state.selectedPeriod,
      type: "Bundle",
      plan: "Personal",
    })
  );
};

export default {
  namespaced: true,
  state: {
    addonsCategories: null,
    subscriptions: [],
    allSelectedAddons: [],
    selectedPeriod: SubscriptionPeriod.annually,
    selectedSubscription: null,
    activeSubscription: null
  },
  getters: {
    showAddonCategory: () => (subscription, addonCategory) => {
      return subscription && addonCategory && subscription.privilegeChildObjects
        ? subscription.privilegeChildObjects.some(
          (privilege) => privilege.privilegeId === addonCategory.dependentPrivilegeId): false;
    },
    isFreePlan: () => (subscription) => {
      if (!subscription) return false;
      return SubscriptionHelper.checkForFreePlan(subscription)
    },
    isTrialPlan: (state, getters, rootState) => () => {
      const userInfo = rootState.UserInfo.user;
      if (!userInfo) return false;
      const userSubscription = userInfo.subscriptionDisplayName;
      const subscriptionIsTrial = userInfo.subscriptionIsTrial;
      if (!userSubscription || !subscriptionIsTrial) return false;
      return userSubscription.toLowerCase().includes("trial") || subscriptionIsTrial;
    },
    checkSubscriptionStatus: (state, getters, rootState) => (status) => {
      const userInfo = rootState.UserInfo.user;
      if (!userInfo) return false;
      const userStatus = userInfo.subscriptionStatus;
      if (!userStatus) return false;
      return userStatus.toLowerCase().includes(status);
    },
    getSelectedAddonByCategory: (state) => (addonCategory) => {
      if (state.allSelectedAddons.length === 0) return null;
      let selectedAddon = null;
      addonCategory.privileges.forEach((privilege) => {
        state.allSelectedAddons.find((addon) => {
          if (privilege && privilege.privilegeId === addon.privilegeId) {
            selectedAddon = privilege;
          }
        });
      });
      return selectedAddon;
    }
  },
  actions: {
    setDefaults({commit}) {
      commit("setDefaults");
    },
    setSubscriptionDataToStorage({state}, data) {
      setPaymentInfoToStorage(data ? data : state);
    },
    setActiveSubscription({commit}, subscriptions) {
      if (!subscriptions) return;
      const activeSubscription = subscriptions.find((subscription) => subscription.isSubscribed);
      commit("setActiveSubscription", activeSubscription ? activeSubscription : null);
    },
    setAddonCategories({commit}, addonCategories) {
      commit("setAddonCategories", addonCategories);
    },
    setAddon({commit, state}, newAddon) {
      newAddon.qty = !!newAddon.qty ? newAddon.qty : 1;
      commit('setAddons', state.allSelectedAddons.concat(newAddon));
    },
    setAddons({commit, state}, addons) {
      commit('setAddons', addons);
    },
    removeAddon({commit, state}, oldAddon) {
      commit('setAddons', state.allSelectedAddons.filter((addon) => addon.privilegeId !== oldAddon.privilegeId));
    },
    setPeriod({commit, state}, period) {
      commit('setPeriod', period);
    },
    setSubscription({commit, state}, selectedSubscription) {
      const subscription = (state.selectedSubscription && selectedSubscription) &&
        (state.selectedSubscription.privilegeId === selectedSubscription.privilegeId) ? null : selectedSubscription;
      commit('setSubscription', subscription);
      const selectedAddons = (subscription) => {
        return state.addonsCategories.reduce((acc, addonCategory) => {
          subscription.privilegeChildObjects.some((privilege) => {
            if (privilege.privilegeId === addonCategory.dependentPrivilegeId) {
              state.allSelectedAddons.forEach((selectedAddon) => {
                const addonToSave = addonCategory.privileges.find(
                  (addon) => addon.privilegeId === selectedAddon.privilegeId);
                if (addonToSave) acc.push(addonToSave);
              })
            }
          })
          return acc;
        }, []);
      }
      if (subscription === null) {
        if (state.allSelectedAddons.length !== 0 && state.activeSubscription !== null) {
          const addons = selectedAddons(state.activeSubscription);
          commit('setAddons', addons);
        } else {
          commit('setAddons', []);
        }
      } else {
        const addons = selectedAddons(state.selectedSubscription);
        commit('setAddons', addons);
      }
    }
  },
  mutations: {
    setDefaults(state) {
      state.addonsCategories = null;
      state.subscriptions = [];
      state.allSelectedAddons = [];
      state.selectedSubscription = null;
      state.activeSubscription = null;
    },
    setAddonCategories(state, addonCategories) {
      state.addonsCategories = addonCategories;
    },
    setAddons(state, addons) {
      state.allSelectedAddons = addons;
    },
    setPeriod(state, period) {
      state.selectedPeriod = period;
    },
    setSubscription(state, subscription) {
      state.selectedSubscription = subscription;
    },
    setActiveSubscription(state, activeSubscription) {
      state.activeSubscription = activeSubscription;
    }
  }
};
<template>
  <div :class="classes">
    <md-table class="d-block" v-if="items">
      <md-table-row
        slot="md-table-row"
        v-for="item in items"
        :key="item.privilegeId"
      >
        <md-table-cell class="md-medium-size-80 md-xsmall-size-80 md-size-80">
          {{ item.privilegeName }}
        </md-table-cell>
        <md-table-cell>{{ item.privilegeValue }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "nav-tabs-table",
  props: {
    multiLevel: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: null,
    },
    hideHeader: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
    },
  },
};
</script>

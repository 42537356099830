import http from "@/http-common";

class Api {
  headers(token) {
    return {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-Authorization": "Bearer " + token,
      },
    };
  }
  paymentMethodHeaders(token) {
    return {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-Authorization": "Bearer " + token,
        "Content-Type": "text/plain; charset=utf-8",
      },
    };
  }

  authHeaders() {
    return {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
  }

  keyHeaders() {
    return {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "x-requested-with": "XMLHttpRequest",
        "auth-signature": "3c776514-505c-4395-adaf-fa7c30200b19",
      },
    };
  }
}

export default Api;

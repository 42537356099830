var render = function render(){var _vm=this,_c=_vm._self._c;return _c('md-card',{staticClass:"md-card-tabs",class:[
    { 'flex-column': _vm.flexColumn },
    { 'nav-pills-icons': _vm.navPillsIcons },
    { 'md-card-plain': _vm.plain },
  ],scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('md-card-header')]},proxy:true}])},[_c('md-card-content',[_c('md-list',{staticClass:"nav-tabs",class:{
        'justify-content-center': _vm.pillsAlign === 'center',
        'justify-content-right': _vm.pillsAlign === 'right',
      }},_vm._l((_vm.tabName),function(item,index){return _c('md-list-item',{key:item,class:[
          { active: _vm.isActivePanel(_vm.tabName[index]) },
          { [_vm.getColorButton(_vm.colorButton)]: _vm.isActivePanel(_vm.tabName[index]) },
        ],on:{"click":function($event){return _vm.switchPanel(_vm.tabName[index])}}},[_vm._v(" "+_vm._s(_vm.tabName[index])+" "),(_vm.navPillsIcons)?_c('md-icon',[_vm._v(" "+_vm._s(_vm.tabIcon[index])+" ")]):_vm._e()],1)}),1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"tab-content"},[_vm._l((_vm.tabName),function(item,index){return [(_vm.isActivePanel(_vm.tabName[index]))?[_c('div',{key:item,class:_vm.getTabContent(index + 1)},[_vm._t(_vm.getTabContent(index + 1))],2)]:_vm._e()]})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import http from "@/http-common";
import {
  UNREAD_USER_LOGS_COUNT,
  DISPLAYED_USER_LOGS,
  MARK_USER_LOGS,
} from "@/api/Constants";
import Api from "@/api/methods/Api";

class ApiUsersLogs extends Api {
  getUnreadUserLogsCount(token) {
    return http.get(UNREAD_USER_LOGS_COUNT, this.headers(token));
  }

  getDisplayedUserLogs(token) {
    return http.get(DISPLAYED_USER_LOGS, this.headers(token));
  }

  markUserLogs(token, data) {
    return http.post(MARK_USER_LOGS, data, this.headers(token));
  }
}

export default new ApiUsersLogs();

import Vue from "vue";
import Router from "vue-router";
import { ROUTER_LINKS } from "@/plugins/Constants.js";
import legacyRoutes from "@/router/routes/legacyRoutes";
import offersRoutes from "@/router/routes/offersRoutes";
Vue.use(Router);

function checkToken() {
  let token = sessionStorage.getItem("token");
  return token && token != null;
}

const router = new Router({
  linkExactActiveClass: "is-active",
  routes: [
    ...offersRoutes,
    ...legacyRoutes
  ],
  scrollBehavior: (to, from, savedPosition) => {
    let position = {};
    if (savedPosition) {
      position = savedPosition;
    } else {
      if (to.hash) {
        let new_y = 0;
        var toolbar = document.getElementById("toolbar");
        if (toolbar != null) {
          const rect_toolbar = toolbar.getBoundingClientRect();
          new_y = rect_toolbar.bottom;
        }

        position = {
          selector: to.hash,
          offset: { x: 0, y: new_y },
          behavior: "smooth",
        };
      } else {
        if (to.query && to.query.anchor) {
          let new_y = 0;
          var toolbar = document.getElementById("toolbar");
          if (toolbar != null) {
            const rect_toolbar = toolbar.getBoundingClientRect();
            new_y = rect_toolbar.bottom;
          }
          position = {
            selector: "#" + to.query.anchor,
            offset: { x: 0, y: new_y },
            behavior: "smooth",
          };
        } else {
          position = { x: 0, y: 0 };
        }
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 500);
    });
  },
});
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
}); // onError

router.beforeEach((to, from, next) => {
  if (to && to.meta && to.meta.hideForAuth && checkToken()) {
    next({ name: ROUTER_LINKS.index.name });
    return;
  }
  let hasToken = to && to.query && to.query.jwt;
  let isLogout = to && to.query && to.query.logout;
  let redirectPath = to && to.query && to.query.redirect;
  //console.log("isLogout=" + isLogout);
  if (to && to.meta && to.meta.requiresAuth && !checkToken() && !hasToken) {
    sessionStorage.clear();

    next({
      name: ROUTER_LINKS.login.name,
      query: { redirect: to.path },
    });
    return;
  }
  if (isLogout && window != null) {
    sessionStorage.clear();
    window.dispatchEvent(
      new CustomEvent("user-logged-out", {
        detail: {
          storage: null,
        },
      })
    );
    if (redirectPath) {
      console.log(
        "external site session expiratin, redirect to: " + redirectPath
      );
      next({
        name: ROUTER_LINKS.login.name,
        query: { redirect: redirectPath },
      });
      return;
    } else {
      if (redirectPath) {
        if (to && to.meta && to.meta.requiresAuth) {
          next({
            name: ROUTER_LINKS.index.name,
          });
          return;
        }
      }
    }
  }
  if (to && to.path != ROUTER_LINKS.terms.path && sessionStorage.getItem("isAgreementExpired") == "true") {
    next({ name: ROUTER_LINKS.terms.name });
    return;
  }
  next();
});

export default router;

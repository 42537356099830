import Vue from "vue";
import moment from "moment";

class DateFormatter extends Vue {
  strToUTC(val, isCustom = false) {
    if (!isCustom) {
      return Date.parse(val); //date parse;
    } else {
      return 0;
    }
  }
  UTCToShortString(val) {
    return moment(val).format("MM/DD/YYYY");
  }
  UTCNowToShortString() {
    return moment().format("MM/DD/YYYY");
  }
  UTCToMonthYear(val) {
    return moment(val).format("MMM YYYY");
  }
  UTCToLongString(val) {
    return moment(val).format("MMM DD, YYYY");
  }
  UTCToLongTimeString(val) {
    return moment(val).format("MMM DD, YYYY HH:mm");
  }
  UTCToAmPmTimeString(val) {
    return moment(val).format("MMM DD, YYYY h:mm A");
  }
  UTCToCustomString(val, format) {
    return moment(val).format(format);
  }
}
export default new DateFormatter();

//UserShort: subscriptionAutoRenewDate,   subscriptionTrialExpDate, subscriptionCancellationDate:  MM/dd/yyyy
//User: subscribeDate, subscriptionTrialExpDate: //"yyyy-MM-dd",
//API: 2022-06-30T12:40:36Z[Etc/UTC]
